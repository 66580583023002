import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const Sidebar = () => {
    const location = useLocation()
    const path = location.pathname

    const type = window.sessionStorage.getItem('type')
    return (
        <div>
            <aside class="main-sidebar sidebar-dark-primary elevation-4">
                {/* <a href="index3.html" class="brand-link">
                    <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style={{ opacity: ".8" }} />
                    <span class="brand-text font-weight-light">AdminLTE 3</span>
                </a> */}
                <div class="sidebar">
                    {/* <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div class="image">
                            <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" />
                        </div>
                        <div class="info">
                            <a href="#" class="d-block">{userName}</a>
                        </div>
                    </div> */}
                    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                        <h5 className="text-white">Quality Enhancement Cell</h5>
                    </div>

                    <nav class="mt-2">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li class="nav-item">
                                <NavLink to='/' className={"nav-link " + (path === "/" ? "active" : "")}>
                                    <i class="nav-icon fas fa-tachometer-alt"></i>
                                    <p>Dashboard</p>
                                </NavLink>
                            </li>
                            {
                                (type === 'T') ?
                                    <>
                                        <li class="nav-item">
                                            <NavLink to='/Assign' className={"nav-link " + (path === "/Assign" ? "active" : "")}>
                                                <i class="nav-icon fas fa-award"></i>
                                                <p>Course Assign</p>
                                            </NavLink>
                                        </li>
                                        <li class="nav-item">
                                            <NavLink to='/MissingCourse' className={"nav-link " + (path === "/MissingCourse" ? "active" : "")}>
                                                <i class="nav-icon fas fa-award"></i>
                                                <p>Courses Without Reg</p>
                                            </NavLink>
                                        </li>
                                    </> :
                                    (type === 'D') ?
                                        <>
                                            <li class="nav-item">
                                                <NavLink to='/Users' className={"nav-link " + (path === "/Users" ? "active" : "")}>
                                                    <i class="nav-icon fa fa-user"></i>
                                                    <p>Department data</p>
                                                </NavLink>
                                            </li>
                                            <li class="nav-item">
                                                <NavLink to='/Assign' className={"nav-link " + (path === "/Assign" ? "active" : "")}>
                                                    <i class="nav-icon fas fa-award"></i>
                                                    <p>Course Assign</p>
                                                </NavLink>
                                            </li>
                                            <li class="nav-item">
                                                <NavLink to='/MissingCourse' className={"nav-link " + (path === "/MissingCourse" ? "active" : "")}>
                                                    <i class="nav-icon fas fa-award"></i>
                                                    <p>Courses Without Reg</p>
                                                </NavLink>
                                            </li>
                                        </> :
                                        (type === 'F') ?
                                            <>

                                            </> :
                                            (type === 'A' || type === 'H') ?
                                                <>
                                                    <li class="nav-item">
                                                        <NavLink to='/Misc' className={"nav-link " + (path === "/Misc" ? "active" : "")}>
                                                            <i class="nav-icon fa fa-bars"></i>
                                                            <p>Misc Entries</p>
                                                        </NavLink>
                                                    </li>
                                                    <li class="nav-item">
                                                        <NavLink to='/PerformaEntries' className={"nav-link " + (path === "/PerformaEntries" ? "active" : "")}>
                                                            <i class="nav-icon fa fa-bars"></i>
                                                            <p>Performa Entries</p>
                                                        </NavLink>
                                                    </li>
                                                    <li class="nav-item">
                                                        <NavLink to='/Users' className={"nav-link " + (path === "/Users" ? "active" : "")}>
                                                            <i class="nav-icon fa fa-user"></i>
                                                            <p>Department data</p>
                                                        </NavLink>
                                                    </li>
                                                    {/* <li class="nav-item">
                                                        <NavLink to='/StudentReport' className={"nav-link " + (path === "/StudentReport" ? "active" : "")}>
                                                            <i class="nav-icon fas fa-users"></i>
                                                            <p>Student Report</p>
                                                        </NavLink>
                                                    </li> */}
                                                    <li class="nav-item">
                                                        <NavLink to='/Assign' className={"nav-link " + (path === "/Assign" ? "active" : "")}>
                                                            <i class="nav-icon fas fa-award"></i>
                                                            <p>Course Assign</p>
                                                        </NavLink>
                                                    </li>

                                                    {/* <li class="nav-item">
                                                        <NavLink to='/FeedbackReport' className={"nav-link " + (path === "/FeedbackReport" ? "active" : "")}>
                                                            <i class="nav-icon fas fa-file-invoice"></i>
                                                            <p>Feedback Report</p>
                                                        </NavLink>
                                                    </li> */}
                                                </> :
                                                <></>
                            }
                            <li class="nav-item">
                                <NavLink to='/AssignReport' className={"nav-link " + (path === "/EnrollReport" ? "active" : "")}>
                                    <i class="nav-icon fas fa-file-invoice"></i>
                                    <p>Assign Report</p>
                                </NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink to='/Logout' className={"nav-link " + (path === "/Logout" ? "active" : "")}>
                                    <i class="nav-icon fas fa-power-off"></i>
                                    <p>Logout</p>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}

export default Sidebar
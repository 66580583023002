import React from 'react'

const Hec = () => {
    return (
        <>
            <h1>In maintenance mode</h1>
        </>
    )
}

export default Hec

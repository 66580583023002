import React, { useState, useEffect } from "react"

const Subject = () => {
    const userType = window.sessionStorage.getItem('type')

    const [sId, setSId] = useState("")
    const [sName, setSName] = useState("")
    const [creditHours, setCreditHours] = useState("")
    const [ch1, setCh1] = useState("")
    const [ch2, setCh2] = useState("")
    const [ch3, setCh3] = useState("")
    const [sDepartment, setSDepartment] = useState("")
    const [sFaculty, setSFaculty] = useState("")
    const [sCode, setSCode] = useState("")
    const [subType, setSubType] = useState(3)
    const [degree, setDegree] = useState(1)
    const [sButtonText, setSButtonText] = useState("Save")
    const [sSuccess, setSSuccess] = useState(false)
    const [sError, setSError] = useState(false)

    const [delSuccess, setDelSuccess] = useState(false)
    const [delError, setDelError] = useState(false)
    const [delErrorText, setDelErrorText] = useState(false)

    const [sFacultyList, setSFacultyList] = useState([])
    const [department1List, setDepartment1List] = useState([])
    const [subjectList, setSubjectList] = useState([])

    useEffect(async () => {
        window.scrollTo(0, 0);
        getFaculty()
    }, [])

    async function getFaculty() {
        let miscRes = await fetch(window.api + "getFaculty")
        miscRes = await miscRes.json()
        setSFacultyList(miscRes.faculty)
        // setSemesterList(miscRes.semester)
        // setSessionList(miscRes.session)
        // setQuestionList(miscRes.question)
        // setDepartmentList(miscRes.department)
        // setSubjectList(miscRes.subject)
    }


    async function getDepartment1(value) {
        setSFaculty(value)
        setDepartment1List([])
        let departmentRes = await fetch(window.api + "getDepartment/" + value)
        departmentRes = await departmentRes.json()
        setDepartment1List(departmentRes.department)
    }

    async function getSubject(value) {
        setSDepartment(value)
        let subjectRes = await fetch(window.api + "getSubject/" + value)
        subjectRes = await subjectRes.json()
        setSubjectList(subjectRes.subject)
    }

    async function addSubject() {

        setSButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', sId)
        formData.append('sFaculty', sFaculty)
        formData.append('sDepartment', sDepartment)
        formData.append('sName', sName)
        formData.append('ch1', ch1)
        formData.append('ch2', ch2)
        formData.append('ch3', ch3)
        formData.append('sCode', sCode)
        formData.append('subType', subType)
        formData.append('degree', degree)

        let result = await fetch(window.api + "addSubject", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setSButtonText("Save"); setSSuccess(false); setSError(true); window.scrollTo(0, 0);
        } else if (result.success) {
            setSError(false); setSButtonText("Save"); setSSuccess(true); setSId(''); setSName(''); setCh1('');
            setCh2(''); setCh3(''); setSCode(''); setSubType(3); setDegree(1); getSubject(sDepartment);
            window.scrollTo(0, 0);
        }
    }
    function updateSubject(id, name, code, ch1, ch2, ch3, subType, degree) {
        setSId(id)
        setSName(name)
        setCh1(ch1)
        setCh2(ch2)
        setCh3(ch3)
        setSCode(code)
        setSubType(subType)
        setDegree(degree)
        setSButtonText("Update")
    }
    async function delSubject(id) {
        let delRes = await fetch(window.api + "delSubject/" + id)
        delRes = await delRes.json()
        if (delRes.assigned) {
            alert("Course Already Found in Assgined Courses")
        } else if (delRes.success) {
            setDelSuccess(true)
            getSubject(sDepartment)
        }
    }

    return (
        <div>
            <div class="card card-primary">
                {/* <div class="card-header">
                                            <h3 class="card-title">Add User</h3>
                                        </div> */}
                <div class="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Course</h3>
                                </div>
                                <form>
                                    <div class="card-body">
                                        {sSuccess
                                            ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                            : <div></div>
                                        }
                                        {sError
                                            ? <div className="alert alert-danger" role="alert">  Subject Already Added.</div>
                                            : <div></div>
                                        }
                                        <div className="row">
                                            <div className="col-md-3 p-1">
                                                <select value={sFaculty} onChange={(e) => getDepartment1(e.target.value)} className="form-control form-control-sm">
                                                    <option value=''>--Select Faculty--</option>
                                                    {Object.keys(sFacultyList).length > 0 ? sFacultyList.map(f => (
                                                        <option value={f.id}>{f.name}</option>
                                                    )) : <></>}
                                                </select>
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <select value={sDepartment} onChange={(e) => getSubject(e.target.value)} className="form-control form-control-sm">
                                                    <option value=''>--Select Department--</option>
                                                    {Object.keys(department1List).length > 0 ? department1List.map(d => (
                                                        <option value={d.id}>{d.name}</option>
                                                    )) : <></>}
                                                </select>
                                            </div>
                                            <div className="col-md-4 p-1">
                                                <input type="text" value={sName} onChange={(e) => setSName(e.target.value)} class="form-control form-control-sm" placeholder="Subject Name" />
                                            </div>
                                            <div className="col-md-2 p-1">
                                                <input type="text" value={sCode} onChange={(e) => setSCode(e.target.value)} class="form-control form-control-sm" placeholder="Course Code" />
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <select className="form-control form-control-sm" value={subType} onChange={(e) => setSubType(e.target.value)}>
                                                    <option value=''>--Select Type--</option>
                                                    <option value='3'>Theory / Paractical</option>
                                                    <option value='1'>Theory</option>
                                                    <option value='2'>Paractical</option>
                                                    <option value='4'>Other</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <input type="number" value={ch1} onChange={(e) => setCh1(e.target.value)} class="form-control form-control-sm" placeholder="e.g 3" />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input type="number" value={ch2} onChange={(e) => setCh2(e.target.value)} class="form-control form-control-sm" placeholder="e.g 2" />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input type="number" value={ch3} onChange={(e) => setCh3(e.target.value)} class="form-control form-control-sm" placeholder="e.g 1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <select className="form-control form-control-sm" value={degree} onChange={(e) => setDegree(e.target.value)}>
                                                    <option value='0'>--Select Degree--</option>
                                                    <option value='1'>UnderGraduate</option>
                                                    <option value='2'>PostGraduate</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" disabled={!sFaculty || !sDepartment || !sName || !ch1 || !ch2 || !ch3 || !sCode || !subType || !degree || userType == 'H'} onClick={addSubject} class="btn btn-primary">{sButtonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Courses List </h3><span className="text-success pl-5">{delSuccess ? 'Deleted Successfully' : ''}</span>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '160px' }}>Type</th>
                                                <th>Degree</th>
                                                <th>Name</th>
                                                <th>Credit Hours</th>
                                                <th>Course Code</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(subjectList).length > 0 ? subjectList.map(s => (
                                                <tr>
                                                    <td>
                                                        {s.sub_type == 1 ? 'Theory' : s.sub_type == 2 ? 'Practical' : s.sub_type == 3 ? 'Theory / Practical' : s.sub_type == 4 ? 'Other' : ''}
                                                    </td>
                                                    <td>
                                                        {s.degree == 1 ? 'UnderGraduate' : s.degree == 2 ? 'PostGraduate' : ''}
                                                    </td>
                                                    <td>{s.name}</td>
                                                    <td>{s.credit_hours}</td>
                                                    <td>{s.course_code}</td>
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateSubject(s.id, s.name, s.course_code, s.ch1, s.ch2, s.ch3, s.sub_type, s.degree)} class="fas fa-user-edit" title='Edit Department'></i>
                                                            </>}
                                                    </td>
                                                    <td className="text-center">
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => delSubject(s.id)} class="fas fa-trash-alt text-danger" title='Delete Department'></i>
                                                            </>}
                                                    </td>
                                                </tr>
                                            )) :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subject
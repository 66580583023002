import React, { useState, useEffect, useRef } from "react"
import { PDFExport, savePDF } from "@progress/kendo-react-pdf"
import { useParams } from "react-router-dom"
import "./css/style.css"


const ViewFeedback = () => {

    const params = useParams()
    const assignId = params.p1

    const container = React.useRef(null)
    const pdfExportComponent = React.useRef(null)


    const [feedbackList, setFeedbackList] = useState([])
    const [assigned, setAssigned] = useState("")
    const [faculty, setFaculty] = useState("")
    const [department, setDepartment] = useState("")
    const [teacher, setTeacher] = useState("")
    const [subject, setSubject] = useState("")
    const [semester, setSemester] = useState("")
    const [session, setSession] = useState("")
    const [student, setStudent] = useState("")

    useEffect(async () => {
        window.scrollTo(0, 0);
        getFeedBackData()
    }, [])

    async function getFeedBackData() {
        let feedBackRes = await fetch(window.api + "viewFeedback/" + assignId)

        feedBackRes = await feedBackRes.json()
        setFeedbackList(feedBackRes.feedback)
        setAssigned(feedBackRes.assigned)
        setFaculty(feedBackRes.faculty)
        setDepartment(feedBackRes.department)
        setTeacher(feedBackRes.teacher)
        setSubject(feedBackRes.subject)
        setSemester(feedBackRes.semester)
        setSession(feedBackRes.session)
        setStudent(feedBackRes.student)
    }

    const exportPDFWithMethod = () => {
        let element = container.current || document.body
        savePDF(
            element, {
            paperSize: "auto",
            margin: 10,
            fileName: `${student.registeration} Feedback ${semester.name}`,
        })
    }

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    }

    const componentRef = useRef()

    return (
        <>
            <br />
            <div class="container-fluid">
                <div className="row">

                    <div className="col-sm-10">
                        <PDFExport
                            ref={pdfExportComponent}
                            paperSize="A4"
                            margin={10}
                            fileName={`Feedback`}
                            author="KendoReact Team"

                        >
                            <div class="card card-primary" ref={container}>
                                <div class="card-header">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <img src='images/logo.png' width='100%' />
                                        </div>
                                        <div className="col-sm-10">
                                            <center>
                                                <h3 style={{ paddingTop: '20px' }}>Quality Enchancement Cell | University of Agriculture Faisalabad</h3>
                                            </center>
                                        </div>
                                        <div className="col-sm-1">
                                            <img style={{ float: 'right' }} src="images/uaf.png" width='100%' />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <center>
                                        <h1>Feedback Performa</h1>
                                    </center>
                                    <div class="myTable">
                                        <table className='half'>
                                            <tr>
                                                <td><b>Faculty:</b> {faculty.name} </td>
                                                <td><b>Department:</b> {department.name} </td>
                                                <td><b>Subject:</b> {subject.name} {subject.credit_hours} </td>
                                            </tr>
                                            <tr>
                                                <td><b>Teacher:</b> {teacher.name} </td>
                                                <td><b>Semester:</b> {semester.name} </td>
                                                <td><b>Session:</b> {session.name} </td>
                                            </tr>
                                            <tr>
                                                <td><b>Registration#:</b> {student.registeration} </td>
                                                <td><b>Name:</b> {student.name} </td>
                                                {/* <td><b>Date of Enrollement:</b> {assigned.created_at.substr(0, 10)} </td> */}
                                            </tr>
                                        </table>
                                        <center>
                                            <h3>Questionire</h3>
                                        </center>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Question</th>
                                                    <th>Answer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(feedbackList).length > 0 ? feedbackList.map(f => (
                                                    <>
                                                        <tr>
                                                            <td style={{ width: '80%' }}> {f.question.question} </td>
                                                            <td style={{ width: '20%' }}>
                                                                {(f.feedback.answer == 1) ?
                                                                    <>Strongly disagree</> :
                                                                    (f.feedback.answer == 2) ?
                                                                        <>Disagree</> :
                                                                        (f.feedback.answer == 3) ?
                                                                            <>Somewhat Agree</> :
                                                                            (f.feedback.answer == 4) ?
                                                                                <>Agree</> :
                                                                                (f.feedback.answer == 5) ?
                                                                                    <>Strongly Agree</> :
                                                                                    <></>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {
                                                                (f.feedback.feed_id == 1) ?
                                                                    <>
                                                                        <b>Comments:</b> --{f.feedback.comment}
                                                                    </> :
                                                                    <></>
                                                            }
                                                        </tr>
                                                    </>

                                                )) :
                                                    <></>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <i>Powered By:</i> <a href="https://www.micrologicx.net/">Micrologicx</a> 
                                    <strong>--Copyright &copy; {(new Date().getFullYear())} .</strong>
                                    All rights reserved.
                                    <div class="float-right d-none d-sm-inline-block">
                                        <b>Version</b> 1.1.1
                                    </div>
                                </div>
                            </div>
                        </PDFExport>
                    </div>
                    <div className="col-sm-2">
                        <div class="card">
                            <div class="card-header">You would like to</div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <i onClick={exportPDFWithMethod}> Download as PDF</i>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewFeedback
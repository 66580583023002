import React from 'react'

const Footer = () => {

    return (
        <div>
            <footer class="main-footer">
                <strong>Copyright &copy; {(new Date().getFullYear())} <a href="#">Danish Enterprises</a>.</strong>
                All rights reserved.
                <div class="float-right d-none d-sm-inline-block">
                    <b>Version</b> 1.1.1
                </div>
            </footer>
            <aside class="control-sidebar control-sidebar-dark"></aside>
        </div>
    )

}

export default Footer
import React, { useState, useEffect } from "react"

const Semester = () => {
    const userType = window.sessionStorage.getItem('type')

    const [seId, setSeId] = useState("")
    const [seName, setSeName] = useState("")
    const [seButtonText, setSeButtonText] = useState("Save")
    const [seSuccess, setSeSuccess] = useState(false)
    const [seError, setSeError] = useState(false)
    const [semesterList, setSemesterList] = useState([])


    useEffect(async () => {
        window.scrollTo(0, 0);
        getSemester()
    }, [])

    async function getSemester() {
        let semesterRes = await fetch(window.api + "getSemester")
        semesterRes = await semesterRes.json()
        setSemesterList(semesterRes.semester)
    }

    async function addSemeter() {
        setSeButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', seId)
        formData.append('name', seName)

        let result = await fetch(window.api + "addSemester", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setSeButtonText("Save")
            setSeSuccess(false)
            setSeError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setSeError(false)
            setSeButtonText("Save")
            setSeSuccess(true)
            setSeName('')
            setSeId('')
            getSemester()
            window.scrollTo(0, 0)
        }
    }


    function updateSemester(id, name) {
        setSeId(id)
        setSeName(name)
        setSeButtonText("Update")
    }
    async function delSemester(id) {
        let delRes = await fetch(window.api + "delSemester/" + id)
        delRes = await delRes.json()
        getSemester()
    }

    return (
        <div>
            <div class="card card-primary">
                {/* <div class="card-header">
                                            <h3 class="card-title">Add User</h3>
                                        </div> */}
                <div class="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Semester</h3>
                                </div>
                                <form>
                                    <div class="card-body">
                                        {seSuccess
                                            ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                            : <div></div>
                                        }
                                        {seError
                                            ? <div className="alert alert-danger" role="alert">  Semester Already Added.</div>
                                            : <div></div>
                                        }
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" value={seName} onChange={(e) => setSeName(e.target.value)} class="form-control" placeholder="Semester" />
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" disabled={!seName || userType == 'H'} onClick={addSemeter} class="btn btn-primary">{seButtonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Semesters List</h3>
                                </div>

                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(semesterList).length > 0 ? semesterList.map(s => (
                                                <tr>
                                                    <td>{s.name}</td>
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateSemester(s.id, s.name)} class="fas fa-user-edit" title='Edit Semester'></i>
                                                            </>}
                                                    </td>
                                                </tr>
                                            )) :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Semester
import React, { useState, useEffect } from "react"

const Session = () => {
    const userType = window.sessionStorage.getItem('type')

    const [sesButtonText, setSesButtonText] = useState("Save")
    const [sesSuccess, setSesSuccess] = useState(false)
    const [sesError, setSesError] = useState(false)
    const [sesName, setSesName] = useState("")
    const [sesId, setSesId] = useState("")
    const [sessionList, setSessionList] = useState([])

    useEffect(async () => {
        window.scrollTo(0, 0);
        getSession()
    }, [])

    async function getSession() {
        let sessionRes = await fetch(window.api + "getSession")
        sessionRes = await sessionRes.json()
        setSessionList(sessionRes.session)
    }

    async function addSession() {
        setSesButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', sesId)
        formData.append('name', sesName)

        let result = await fetch(window.api + "addSession", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setSesButtonText("Save")
            setSesSuccess(false)
            setSesError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setSesError(false)
            setSesButtonText("Save")
            setSesSuccess(true)
            setSesName('')
            setSesId('')
            getSession()
            window.scrollTo(0, 0)
        }
    }


    function updateSession(id, name) {
        setSesId(id)
        setSesName(name)
        setSesButtonText("Update")
    }

    async function updateStatus(id, status) {

        const formData = new FormData()
        formData.append('id', id)
        if (status == 1) {
            formData.append('satus', 0)
        } else {
            formData.append('satus', 1)
        }

        let result = await fetch(window.api + "updateSessStatus", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.success) {
            getSession()
        }

    }
    async function delSession(id) {
        let delRes = await fetch(window.api + "delSession/" + id)
        delRes = await delRes.json()
        getSession()
    }

    return (
        <div>
            <div class="card card-primary">
                {/* <div class="card-header">
                                            <h3 class="card-title">Add User</h3>
                                        </div> */}
                <div class="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Session</h3>
                                </div>
                                <form>
                                    <div class="card-body">
                                        {sesSuccess
                                            ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                            : <div></div>
                                        }
                                        {sesError
                                            ? <div className="alert alert-danger" role="alert">  Session Already Added.</div>
                                            : <div></div>
                                        }
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" value={sesName} onChange={(e) => setSesName(e.target.value)} class="form-control" placeholder="Session Name" />
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" disabled={!sesName || userType == 'H'} onClick={addSession} class="btn btn-primary">{sesButtonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Sessions List</h3>
                                </div>

                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Edit</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(sessionList).length > 0 ? sessionList.map(s => (
                                                <tr>
                                                    <td>{s.name}</td>
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateSession(s.id, s.name)} class="fas fa-user-edit" title='Edit Semester'></i>
                                                            </>}
                                                    </td>
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateStatus(s.id, s.Locked)}>{s.Locked == 1 ? "Start" : "Stop"}</i>
                                                            </>}
                                                    </td>
                                                </tr>
                                            )) :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Session
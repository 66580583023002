import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import InputMask from 'react-input-mask'

const Users = () => {
    const userPassword = window.sessionStorage.getItem('password')
    const userType = window.sessionStorage.getItem('type')
    const userId = window.sessionStorage.getItem('id')
    const userFaculty = window.sessionStorage.getItem('userFaculty')

    const [random, setRandom] = useState(Math.floor(Math.random() * 99999))

    const [sId, setSId] = useState("")
    const [sName, setSName] = useState("")
    const [sDepartment, setSDepartment] = useState("")
    const [sFaculty, setSFaculty] = useState("")
    const [sRegistration, setSRegistration] = useState("")
    const [sButtonText, setSButtonText] = useState("Save")
    const [sSuccess, setSSuccess] = useState(false)
    const [sError, setSError] = useState(false)

    const [sFacultyList, setSFacultyList] = useState([])
    const [sDepartmentList, setSDepartmentList] = useState([])
    const [tFacultyList, setTFacultyList] = useState([])
    const [tDepartmentList, setTDepartmentList] = useState([])
    const [suFacultyList, setSuFacultyList] = useState([])
    const [suDepartmentList, setSuDepartmentList] = useState([])
    const [studentList, setStudentList] = useState([])

    const [tId, setTId] = useState("")
    const [tName, setTName] = useState("")
    const [tDepartment, setTDepartment] = useState("")
    const [tFaculty, setTFaculty] = useState("")
    const [tDesignation, setTDesignation] = useState("")
    const [tEmail, setTEmail] = useState("")

    const [suId, setSuId] = useState("")
    const [suName, setSuName] = useState("")
    const [suDepartment, setSuDepartment] = useState("")
    const [suFaculty, setSuFaculty] = useState("")
    const [creditHours, setCreditHours] = useState("")
    const [ch1, setCh1] = useState("")
    const [ch2, setCh2] = useState("")
    const [ch3, setCh3] = useState("")
    const [suCode, setSuCode] = useState("")
    const [subType, setSubType] = useState(3)
    const [degree, setDegree] = useState(1)
    const [suButtonText, setSuButtonText] = useState("Save")
    const [suSuccess, setSuSuccess] = useState(false)
    const [suError, setSuError] = useState(false)
    const [tButtonText, setTButtonText] = useState("Save")
    const [tSuccess, setTSuccess] = useState(false)
    const [tError, setTError] = useState(false)
    const [pButtonText, setPButtonText] = useState("update")
    const [pSuccess, setPSuccess] = useState(false)
    const [pError, setPError] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [teacherList, setTeacherList] = useState([])
    const [subjectList, setSubjectList] = useState([])

    const [delSuccess, setDelSuccess] = useState(false)
    const [delError, setDelError] = useState(false)
    const [delErrorText, setDelErrorText] = useState(false)

    useEffect(async () => {
        window.scrollTo(0, 0);
        getMisc()
        getStudent(userId)
    }, [])
    async function getMisc() {
        if (userType === 'D') {
            let miscRes = await fetch(window.api + "getMiscFaculty/" + userFaculty + "/" + userId)
            miscRes = await miscRes.json()
            setSFaculty(miscRes.faculty.id)
            setSuFaculty(miscRes.faculty.id)
            setTFaculty(miscRes.faculty.id)
            setSDepartment(miscRes.department.id)
            setSuDepartment(miscRes.department.id)
            setTDepartment(miscRes.department.id)
        } else {
            let miscRes = await fetch(window.api + "getFaculty")
            miscRes = await miscRes.json()
            setSFacultyList(miscRes.faculty)
            setTFacultyList(miscRes.faculty)
            setSuFacultyList(miscRes.faculty)
        }
    }
    async function getStudent(value) {
        setSDepartment(value)
        let studentRes = await fetch(window.api + "getStudent/" + value)
        studentRes = await studentRes.json()
        setStudentList(studentRes.student)
    }
    async function addStudent() {
        setSButtonText("Saving...")
        const formData = new FormData()
        formData.append('sId', sId)
        formData.append('sFaculty', sFaculty)
        formData.append('sDepartment', sDepartment)
        formData.append('sName', sName)
        formData.append('sRegistration', sRegistration)

        let result = await fetch(window.api + "addStudent", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setSButtonText("Save")
            setSSuccess(false)
            setSError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setSError(false)
            setSButtonText("Save")
            setSSuccess(true)
            setSName('')
            setSRegistration('')
            setSId('')
            getStudent(sDepartment)
            window.scrollTo(0, 0)
        }
    }
    function updateStudent(id, name, registration) {
        setSId(id)
        setSName(name)
        setSRegistration(registration)
        setSButtonText("Update")
    }
    async function delStudent(id) {
        let delRes = await fetch(window.api + "delFaculty/" + id)
        delRes = await delRes.json()
        getStudent(sDepartment)
    }
    async function getSDepartment(fId) {
        setSFaculty(fId)
        let miscRes = await fetch(window.api + "getDepartment/" + fId)
        miscRes = await miscRes.json()
        setSDepartmentList(miscRes.department)
    }

    // Teacher Function

    async function getTeacher(value) {
        setTDepartment(value)
        let teacherRes = await fetch(window.api + "getTeacher/" + value)
        teacherRes = await teacherRes.json()
        setTeacherList(teacherRes.teacher)
    }

    async function addTeacher() {
        setTButtonText("Saving...")
        const formData = new FormData()
        formData.append('tId', tId)
        formData.append('tFaculty', tFaculty)
        formData.append('tDepartment', tDepartment)
        formData.append('tName', tName)
        formData.append('tdesignation', tDesignation)
        formData.append('temail', tEmail)

        let result = await fetch(window.api + "addTeacherByDepartment", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setTButtonText("Save")
            setTSuccess(false)
            setTError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setTError(false)
            setTButtonText("Save")
            setTSuccess(true)
            setTName('')
            // setTDesignation('')
            setTId('')
            setTEmail('')
            getTeacher(tDepartment)
            window.scrollTo(0, 0)
        }
    }

    function updateTeacher(id, name, designation, email) {
        setTId(id)
        setTName(name)
        setTDesignation(designation)
        setTEmail(email)
        setTButtonText("Update")
    }

    async function delTeacher(id) {
        let delRes = await fetch(window.api + "delTeacher/" + id)
        delRes = await delRes.json()
        getTeacher(tDepartment)
    }
    async function getTDepartment(fId) {
        setTFaculty(fId)
        let miscRes = await fetch(window.api + "getDepartment/" + fId)
        miscRes = await miscRes.json()
        setTDepartmentList(miscRes.department)
    }

    async function updatePassword() {
        setPButtonText("Saving...")
        const formData = new FormData()
        formData.append('userId', userId)
        formData.append('password', newPassword)

        let result = await fetch(window.api + "updatePassword", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result.error) {
            setPButtonText("Update")
            setPSuccess(false)
            setPError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setPError(false)
            setPButtonText("Update")
            setPSuccess(true)
            setOldPassword("")
            setNewPassword("")
            setConfirmPassword("")
            window.sessionStorage.setItem("password", newPassword)
            window.scrollTo(0, 0)
        }
    }

    async function getSubject(value) {
        setSuDepartment(value)
        let subjectRes = await fetch(window.api + "getSubject/" + value)
        subjectRes = await subjectRes.json()
        setSubjectList(subjectRes.subject)
    }

    async function addSubject() {
        setSuButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', suId)
        formData.append('sFaculty', suFaculty)
        formData.append('sDepartment', suDepartment)
        formData.append('sName', suName)
        formData.append('ch1', ch1)
        formData.append('ch2', ch2)
        formData.append('ch3', ch3)
        formData.append('sCode', suCode)
        formData.append('subType', subType)
        formData.append('degree', degree)

        let result = await fetch(window.api + "addSubject", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setSuButtonText("Save")
            setSuSuccess(false)
            setSuError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setSuError(false)
            setSuButtonText("Save")
            setSuSuccess(true)
            setSuId('')
            setSuName('')
            setCh1('')
            setCh2('')
            setCh3('')
            setSuCode('')
            setSubType(3)
            setDegree(1)
            getSubject(suDepartment)
            window.scrollTo(0, 0)
        }
    }
    function updateSubject(id, name, code, ch1, ch2, ch3, subType, degree) {
        setSuId(id)
        setSuName(name)
        setSuCode(code)
        setCh1(ch1)
        setCh2(ch2)
        setCh3(ch3)
        setSubType(subType)
        setDegree(degree)
        setSuButtonText("Update")
    }
    async function delSubject(id) {
        let delRes = await fetch(window.api + "delSubject/" + id)
        delRes = await delRes.json()
        if (delRes.assigned) {
            alert("Course Already Found in Assgined Courses")
        } else if (delRes.success) {
            setDelSuccess(true)
            getSubject(suDepartment)
        }
    }

    async function getSuDepartment(fId) {
        setSuFaculty(fId)
        let miscRes = await fetch(window.api + "getDepartment/" + fId)
        miscRes = await miscRes.json()
        setSuDepartmentList(miscRes.department)
    }

    return (
        <>
            <div class="wrapper">
                <Navbar />
                <Sidebar />
                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    {/* <h1 class="m-0">Department Data</h1> */}
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Department Data</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="content">
                        <div class="container-fluid">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                {/* <li class="nav-item" role="presentation">
                                    <button class="nav-link" onClick={() => getStudent(userId)} id="student-tab" data-bs-toggle="tab" data-bs-target="#student" type="button" role="tab" aria-controls="student" aria-selected="true">Student</button>
                                </li> */}
                                {/* <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="teacher-tab" onClick={() => getTeacher(userId)} data-bs-toggle="tab" data-bs-target="#teacher" type="button" role="tab" aria-controls="teacher" aria-selected="false">Teacher</button>
                                </li> */}
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="subject-tab" onClick={() => getSubject(userId)} data-bs-toggle="tab" data-bs-target="#subject" type="button" role="tab" aria-controls="subject" aria-selected="false">Course</button>
                                </li>
                                {(userType === 'D') ?
                                    <>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="password-tab" data-bs-toggle="tab" data-bs-target="#password" type="button" role="tab" aria-controls="password" aria-selected="false">Update Password</button>
                                        </li>
                                    </> :
                                    <></>}
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" id="student" role="tabpanel" aria-labelledby="student-tab">
                                    <div class="card card-primary">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div class="card card-primary">
                                                        <div class="card-header">
                                                            <h3 class="card-title">Add Student</h3>
                                                        </div>
                                                        <form>
                                                            <div class="card-body">
                                                                {sSuccess
                                                                    ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                                                    : <div></div>
                                                                }
                                                                {sError
                                                                    ? <div className="alert alert-danger" role="alert">  Student Already Added.</div>
                                                                    : <div></div>
                                                                }
                                                                {(userType === 'D') ?
                                                                    <></> :
                                                                    <>
                                                                        <div className="form-group">
                                                                            <label>Faculty</label>
                                                                            <select value={sFaculty} onChange={(e) => getSDepartment(e.target.value)} className="form-control">
                                                                                <option value=''>--Select Faculty--</option>
                                                                                {Object.keys(sFacultyList).length > 0 ? sFacultyList.map(f => (
                                                                                    <option value={f.id}>{f.name}</option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>
                                                                    </>}
                                                                <div className="form-group">
                                                                    {(userType === 'D') ?
                                                                        <></> :
                                                                        <>
                                                                            <label>Department</label>
                                                                            <select value={sDepartment} onChange={(e) => getStudent(e.target.value)} className="form-control">
                                                                                <option value=''>--Select Department--</option>
                                                                                {Object.keys(sDepartmentList).length > 0 ? sDepartmentList.map(d => (
                                                                                    <option value={d.id}>{d.name}</option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </>}
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Name</label>
                                                                    <input type="text" value={sName} onChange={(e) => setSName(e.target.value)} class="form-control" placeholder="Enter Student Name" />
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Registration#</label>
                                                                    <InputMask type="text" value={sRegistration} onChange={(e) => setSRegistration(e.target.value)} className="form-control" mask="2099-Ag-99999" maskChar=" " />
                                                                </div>
                                                            </div>
                                                            <div class="card-footer">
                                                                <button type="button" disabled={!sFaculty || !sDepartment || !sName || !sRegistration} onClick={addStudent} class="btn btn-primary">{sButtonText}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h3 class="card-title">All Students</h3>
                                                        </div>

                                                        <div class="card-body p-0">
                                                            <table class="table table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        {/* <th style={{ width: "10px" }}>#</th> */}
                                                                        <th>Name</th>
                                                                        <th>Registration#</th>
                                                                        <th>Edit</th>
                                                                        {/* <th>Delete</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(studentList).length > 0 ? studentList.map(s => (
                                                                        <tr>
                                                                            {/* <td>{s.id}</td> */}
                                                                            <td>{s.name}</td>
                                                                            <td>{s.registeration}</td>
                                                                            <td>
                                                                                <i onClick={() => updateStudent(s.id, s.name, s.registeration)} class="fas fa-user-edit" title='Edit Student'></i>
                                                                            </td>
                                                                            {/* <td>
                                                                                <i onClick={() => delStudent(s.id)} class="fas fa-trash-alt" title='Delete Student'></i>
                                                                            </td> */}
                                                                        </tr>
                                                                    )) :
                                                                        <></>}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="teacher" role="tabpanel" aria-labelledby="teacher-tab">
                                    <div class="card card-primary">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div class="card card-primary">
                                                        <div class="card-header">
                                                            <h3 class="card-title">Add Teacher</h3>
                                                        </div>
                                                        <form>
                                                            <div class="card-body">
                                                                {tSuccess
                                                                    ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                                                    : <div></div>
                                                                }
                                                                {tError
                                                                    ? <div className="alert alert-danger" role="alert">  Teacher Already Added.</div>
                                                                    : <div></div>
                                                                }
                                                                <div className="row">
                                                                    {(userType === 'D') ?
                                                                        <></> :
                                                                        <>
                                                                            <div className="col-md-3 p-1">
                                                                                <select value={tFaculty} onChange={(e) => getTDepartment(e.target.value)} className="form-control form-control-sm">
                                                                                    <option value=''>--Select Faculty--</option>
                                                                                    {Object.keys(tFacultyList).length > 0 ? tFacultyList.map(f => (
                                                                                        <option value={f.id}>{f.name}</option>
                                                                                    )) : <></>}
                                                                                </select>
                                                                            </div>
                                                                        </>}
                                                                    {(userType === 'D') ?
                                                                        <></> :
                                                                        <>
                                                                            <div className="col-md-3 p-1">
                                                                                <select value={tDepartment} onChange={(e) => getTeacher(e.target.value)} className="form-control form-control-sm">
                                                                                    <option value=''>--Select Department--</option>
                                                                                    {Object.keys(tDepartmentList).length > 0 ? tDepartmentList.map(d => (
                                                                                        <option value={d.id}>{d.name}</option>
                                                                                    )) : <></>}
                                                                                </select>
                                                                            </div>
                                                                        </>}
                                                                    <div className="col-md-3 p-1">
                                                                        <select value={tDesignation} onChange={(e) => setTDesignation(e.target.value)} className="form-control form-control-sm">
                                                                            <option>--Select Designation--</option>
                                                                            <option value="1">Professor</option>
                                                                            <option value="2">Associate Professor</option>
                                                                            <option value="3">Assistant Professor</option>
                                                                            <option value="4">Lecturer</option>
                                                                            <option value="5">Visiting</option>
                                                                            <option value="6">Subject Expert</option>
                                                                            <option value="7">Research Officer</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-3 p-1">
                                                                        <input type="text" value={tName} onChange={(e) => setTName(e.target.value)} class="form-control form-control-sm" placeholder="Teacher Name" />
                                                                    </div>
                                                                    <div className="col-md-3 p-1">
                                                                        <input type="text" value={tEmail} onChange={(e) => setTEmail(e.target.value)} class="form-control form-control-sm" placeholder="Email Address" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-footer">
                                                                <button type="button" disabled={!tFaculty || !tDepartment || !tName || !tDesignation || !tEmail} onClick={addTeacher} class="btn btn-primary">{tButtonText}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h3 class="card-title">Teachers List</h3>
                                                        </div>
                                                        <div class="card-body p-0">
                                                            <table class="table table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        {/* <th style={{ width: "10px" }}>#</th> */}
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Designation</th>
                                                                        <th>Edit</th>
                                                                        {/* <th>Delete</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(teacherList).length > 0 ? teacherList.map(t => (
                                                                        <tr>
                                                                            {/* <td>{t.id}</td> */}
                                                                            <td>{t.name}</td>
                                                                            <td>{t.email}</td>
                                                                            <td>
                                                                                {(t.designation == 1) ?
                                                                                    "Professor" :
                                                                                    (t.designation == 2) ?
                                                                                        "Associate Professor" :
                                                                                        (t.designation == 3) ?
                                                                                            "Assistant Professor" :
                                                                                            (t.designation == 4) ?
                                                                                                "Lecturer" :
                                                                                                (t.designation == 5) ?
                                                                                                    "Visiting" :
                                                                                                    (t.designation == 6) ?
                                                                                                        "Subject Expert" :
                                                                                                        (t.designation == 7) ?
                                                                                                            "Research Officer" :
                                                                                                            ""
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <i onClick={() => updateTeacher(t.id, t.name, t.designation, t.email)} class="fas fa-user-edit" title='Edit Teacher'></i>
                                                                            </td>
                                                                            {/* <td>
                                                                                <i onClick={() => delTeacher(t.id)} class="fas fa-trash-alt" title='Delete Teacher'></i>
                                                                            </td> */}
                                                                        </tr>
                                                                    )) :
                                                                        <></>}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade show active" id="subject" role="tabpanel" aria-labelledby="subject-tab">
                                    <div class="card card-primary">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div class="card card-primary">
                                                        <div class="card-header">
                                                            <h3 class="card-title">Add Course</h3>
                                                        </div>
                                                        <form>
                                                            <div class="card-body">
                                                                {suSuccess
                                                                    ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                                                    : <div></div>
                                                                }
                                                                {suError
                                                                    ? <div className="alert alert-danger" role="alert">  Subject Already Added.</div>
                                                                    : <div></div>
                                                                }
                                                                <div className="row">
                                                                    {(userType === 'D') ?
                                                                        <></> :
                                                                        <>
                                                                            <div className="col-md-3 p-1">

                                                                                <select value={suFaculty} onChange={(e) => getSuDepartment(e.target.value)} className="form-control form-control-sm">
                                                                                    <option value=''>--Select Faculty--</option>
                                                                                    {Object.keys(suFacultyList).length > 0 ? suFacultyList.map(f => (
                                                                                        <option value={f.id}>{f.name}</option>
                                                                                    )) : <></>}
                                                                                </select>

                                                                            </div>
                                                                        </>}
                                                                    {(userType === 'D') ?
                                                                        <></> :
                                                                        <>
                                                                            <div className="col-md-3 p-1">

                                                                                <select value={suDepartment} onChange={(e) => getSubject(e.target.value)} className="form-control form-control-sm">
                                                                                    <option value=''>--Select Department--</option>
                                                                                    {Object.keys(suDepartmentList).length > 0 ? suDepartmentList.map(d => (
                                                                                        <option value={d.id}>{d.name}</option>
                                                                                    )) : <></>}
                                                                                </select>

                                                                            </div>
                                                                        </>}
                                                                    <div className="col-md-4 p-1">

                                                                        <input type="text" value={suName} onChange={(e) => setSuName(e.target.value)} class="form-control form-control-sm" placeholder="Course Name" />

                                                                    </div>

                                                                    <div className="col-md-2 p-1">

                                                                        <input type="text" value={suCode} onChange={(e) => setSuCode(e.target.value)} class="form-control form-control-sm" placeholder="Course Code" />

                                                                    </div>
                                                                    <div className="col-md-3 p-1">
                                                                        <select className="form-control form-control-sm" value={subType} onChange={(e) => setSubType(e.target.value)}>
                                                                            <option value=''>--Select Type--</option>
                                                                            <option value='3'>Theory / Paractical</option>
                                                                            <option value='1'>Theory</option>
                                                                            <option value='2'>Paractical</option>
                                                                            <option value='4'>Other</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-3 p-1">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <input type="number" value={ch1} onChange={(e) => setCh1(e.target.value)} class="form-control form-control-sm " placeholder="e.g 3" />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <input type="number" value={ch2} onChange={(e) => setCh2(e.target.value)} class="form-control form-control-sm" placeholder="e.g 2" />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <input type="number" value={ch3} onChange={(e) => setCh3(e.target.value)} class="form-control form-control-sm" placeholder="e.g 1" />
                                                                            </div>
                                                                        </div>
                                                                        <i className="text-danger" style={{ fontSize: '12px' }}>Credit Hours: 3(2-1)</i>
                                                                    </div>
                                                                    <div className="col-md-3 p-1">
                                                                        <select className="form-control form-control-sm" value={degree} onChange={(e) => setDegree(e.target.value)}>
                                                                            <option value=''>--Select Degree--</option>
                                                                            <option value='1'>UnderGraduate</option>
                                                                            <option value='2'>PostGraduate</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-footer">
                                                                <button type="button" disabled={!suFaculty || !suDepartment || !suName || !ch1 || !ch2 || !ch3 || !suCode || !subType || !degree || userType == 'H'} onClick={addSubject} class="btn btn-primary">{suButtonText}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h3 class="card-title">Courses List</h3><span className="text-success pl-5">{delSuccess ? 'Deleted Successfully' : ''}</span>
                                                        </div>
                                                        <div class="card-body p-0">
                                                            <table class="table table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: '160px' }}>Type</th>
                                                                        <th>Degree</th>
                                                                        <th>Name</th>
                                                                        <th>Credit Hours</th>
                                                                        <th>Course Code</th>
                                                                        <th>Edit</th>
                                                                        <th>Delete</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(subjectList).length > 0 ? subjectList.map(s => (
                                                                        <tr>
                                                                            <td>
                                                                                {s.sub_type == 1 ? 'Theory' : s.sub_type == 2 ? 'Practical' : s.sub_type == 3 ? 'Theory / Practical' : s.sub_type == 4 ? 'Other' : ''}
                                                                            </td>
                                                                            <td>
                                                                                {s.degree == 1 ? 'UnderGraduate' : s.degree == 2 ? 'PostGraduate' : ''}
                                                                            </td>
                                                                            <td>{s.name}</td>
                                                                            <td>{s.credit_hours}</td>
                                                                            <td>{s.course_code}</td>
                                                                            <td>
                                                                                {(userType == 'H') ?
                                                                                    <>
                                                                                        <i>Disabled</i>
                                                                                    </> :
                                                                                    <>
                                                                                        <i onClick={() => updateSubject(s.id, s.name, s.course_code, s.ch1, s.ch2, s.ch3, s.sub_type, s.degree)} class="fas fa-user-edit" title='Edit Department'></i>
                                                                                    </>}
                                                                            </td>
                                                                            <td>
                                                                                {(userType == 'H') ?
                                                                                    <>
                                                                                        <i>Disabled</i>
                                                                                    </> :
                                                                                    <>
                                                                                        <i onClick={() => delSubject(s.id)} class="fas fa-trash-alt" title='Delete Subject'></i>
                                                                                    </>}
                                                                            </td>
                                                                        </tr>
                                                                    )) :
                                                                        <></>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="password" role="tabpanel" aria-labelledby="password-tab">
                                    <div class="card card-primary">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <form>
                                                        <div class="card-body">
                                                            {pSuccess
                                                                ? <div className="alert alert-success" role="alert"> Update successfully.</div>
                                                                : <div></div>
                                                            }
                                                            {pError
                                                                ? <div className="alert alert-danger" role="alert">  Error Occured</div>
                                                                : <div></div>
                                                            }
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <div class="form-group">
                                                                        <label>Old Password</label>
                                                                        <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} class="form-control form-control-sm" placeholder="Enter Old Password" />
                                                                        {
                                                                            (!oldPassword) ?
                                                                                <></> :
                                                                                <>
                                                                                    {
                                                                                        (oldPassword === userPassword) ?
                                                                                            <>

                                                                                            </> :
                                                                                            <>
                                                                                                <span style={{ color: 'red' }}>incorrect password</span>
                                                                                            </>
                                                                                    }
                                                                                </>

                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div class="form-group">
                                                                        <label>New Password</label>
                                                                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} class="form-control form-control-sm" placeholder="Enter New Password" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div class="form-group">
                                                                        <label>Confirm Password</label>
                                                                        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} class="form-control form-control-sm" placeholder="Confirm Password" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer">
                                                            <button type="button" disabled={oldPassword !== userPassword || !oldPassword || !newPassword || newPassword !== confirmPassword} onClick={updatePassword} class="btn btn-primary">{pButtonText}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
                <Footer />
            </div >
        </>
    )
}

export default Users
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Forgot = () => {
    const navigate = useNavigate()
    const [buttonText, setButtonText] = useState("Send")
    const [errorText, setErrorText] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [type, setType] = useState("")
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
        if (window.sessionStorage.getItem("id")) {
            navigate("/")
        }
    }, [])

    async function Forgot() {
        setButtonText("Checking")
        const formData = new FormData()
        formData.append('email', email)

        let result = await fetch(window.api + "forgotTeacher", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        if (result.error) {
            setError(true)
            setErrorText(error)
            setButtonText("Send")
        } else {
            setError(false)
            setButtonText("Send")
            setSuccess(result.success)
        }
    }
    return (
        <>
            <div className='hold-transition login-page'>
                <div class="login-box">
                    <div class="login-logo">
                        <a href="#"><b>QEC</b>Portal</a>
                    </div>
                    <div class="card">
                        <div class="card-body login-card-body">
                            <p class="login-box-msg">Forgot Your Password</p>
                            {error ?
                                <div className="alert alert-danger alert-dismissible">
                                    {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button> */}
                                    {errorText}
                                </div> :
                                <></>
                            }
                            
                            <div class="input-group mb-3">
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} class="form-control" placeholder="Email" />
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8"></div>
                                <div class="col-4">
                                    <button type="button" onClick={Forgot} class="btn btn-primary btn-block">{buttonText}</button>
                                </div>
                            </div>

                            <div class="social-auth-links text-center mb-3">
                                <p>- OR -</p>
                            </div>
                            <p class="mb-1">
                                You know your password? <Link to="/Login"> Login here</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot

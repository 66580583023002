import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import InputMask from 'react-input-mask'
import formatFile from './files/format.csv'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

const StudentReport = () => {
    const userPassword = window.sessionStorage.getItem('password')
    const userType = window.sessionStorage.getItem('type')
    const userId = window.sessionStorage.getItem('id')
    const userFaculty = window.sessionStorage.getItem('userFaculty')
    const userDepartment = window.sessionStorage.getItem('userDepartment')

    const [faculty, setFaculty] = useState("")
    const [department, setDepartment] = useState("")
    const [facultyList, setFacultyList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [studentList, setStudentList] = useState([])

    const [isChecked, setIsChecked] = useState([])

    const [loader, setLoader] = useState(false)
    const [approvLoader, setApprovLoader] = useState(false)

    useEffect(async () => {
        window.scrollTo(0, 0);
        getAssignData()
    }, [])

    async function getAssignData() {
        let assignDataRes = await fetch(window.api + "getAssignData")
        assignDataRes = await assignDataRes.json()
        setFacultyList(assignDataRes.faculty)
        if (userType === 'D') {
            getStudentReportByDepartment(userFaculty, userId)
        }
    }

    async function getStudentReportByDepartment(fac, dept) {
        setLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)

        let result = await fetch(window.api + "getRegStudentList", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result) {
            setLoader(false)
            setStudentList(result.regStudentList)
        }
    }

    const [agYear, setAgYear] = useState("")
    const [agNo, setAgNo] = useState("")
    const [getLoader, setGetLoader] = useState(false)
    const [studentData, setStudentData] = useState("")
    const [enrollmentData, setEnrollmentData] = useState([])

    async function getRecord() {
        setGetLoader(true)
        const regNum = agYear + '-ag-' + agNo;
        const formData = new FormData()
        formData.append('reg_num', regNum)

        let result = await fetch(window.api + "getStudentRecord", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result) {
            setGetLoader(false)
            setStudentData(result.student)
            setEnrollmentData(result.enrolled)
        }
    }

    const [stId, setStId] = useState("")
    const [stDept, setStDept] = useState("")
    const [stReg, setStReg] = useState("")
    const [stName, setStName] = useState("")
    const [stEmail, setStEmail] = useState("")
    const [stPhone, setStPhone] = useState("")
    const [stPassword, setStPassword] = useState("")

    const [editShow, setEditShow] = useState(false)
    const [deptList, setDeptList] = useState([])

    async function editStudent(idVal, deptVal, regVal, nameVal, emailVal, phoneVal, passwordVal) {
        setEditShow(true)
        setStId(idVal)
        setStDept(deptVal)
        setStReg(regVal)
        setStName(nameVal)
        setStEmail(emailVal)
        setStPhone(phoneVal)
        setStPassword(passwordVal)
        let deptRes = await fetch(window.api + "getAllDepartments")
        deptRes = await deptRes.json()
        setDeptList(deptRes.departments)
    }

    function closeEdit() {
        setEditShow(false)
        setStId("")
        setStDept("")
        setStReg("")
        setStName("")
        setStEmail("")
        setStPhone("")
        setStPassword("")
    }

    const [updateLoader, setUpdateLoader] = useState(false)

    async function updateStudent() {
        setUpdateLoader(true)
        const formData = new FormData()
        formData.append('st_id', stId)
        formData.append('st_dept', stDept)
        formData.append('st_name', stName)
        formData.append('st_email', stEmail)
        formData.append('st_phone', stPhone)
        formData.append('st_password', stPassword)

        let result = await fetch(window.api + "updateStudent", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result.success) {
            setUpdateLoader(false)
            closeEdit()
            getRecord()
        }
    }

    return (
        <>
            <div className="wrapper">
                <Navbar />
                <Sidebar />
                <Modal show={editShow} onHide={closeEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Student</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="p-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={stReg}
                                        onChange={(e) => setStReg(e.target.value)}
                                        readOnly
                                    />
                                </div>
                                <div className="p-1">
                                    <label>Department</label>
                                    <select value={stDept} onChange={(e) => setStDept(e.target.value)} className="form-control form-control-sm">
                                        <option value=''>--Select Department--</option>
                                        {Object.keys(deptList).length > 0 ? deptList.map((d, index) => (
                                            <option key={index} value={d.id}>{d.name}</option>
                                        )) : <></>}
                                    </select>
                                </div>
                                <div className="p-1">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={stName}
                                        onChange={(e) => setStName(e.target.value)}
                                    />
                                </div>
                                <div className="p-1">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={stEmail}
                                        onChange={(e) => setStEmail(e.target.value)}
                                    />
                                </div>
                                <div className="p-1">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={stPhone}
                                        onChange={(e) => setStPhone(e.target.value)}
                                    />
                                </div>
                                <div className="p-1">
                                    <label>Password</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={stPassword}
                                        onChange={(e) => setStPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            onClick={updateStudent}
                            disabled={!stDept || !stReg || !stName || !stEmail || !stPhone || !stPassword}
                        >
                            {updateLoader ? 'Updating..' : 'Update'}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Student Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Student Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Student Data</h3>
                                        </div>
                                        <form>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-2 offset-3 p-0">
                                                        <input
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            value={agYear}
                                                            onChange={(e) => setAgYear(e.target.value)}
                                                            placeholder="e.g 2022"
                                                        />
                                                    </div>
                                                    <div className="col-sm-1 p-0 text-center">
                                                        <label className="">-AG-</label>
                                                    </div>
                                                    <div className="col-sm-2 p-0">
                                                        <input
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            value={agNo}
                                                            onChange={(e) => setAgNo(e.target.value)}
                                                            placeholder="e.g 12345"
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-sm"
                                                            disabled={!agYear || !agNo}
                                                            onClick={getRecord}
                                                        >
                                                            {getLoader ? 'Getting....' : 'Get Record'}
                                                        </button>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {(getLoader) ?
                                                            <div className="text-center">
                                                                <i>Fetching Data Please Wait...</i>
                                                            </div> :
                                                            <>
                                                                {studentData ?
                                                                    <>
                                                                        <h4 className="text-center">Personal Information</h4>
                                                                        <span className="float-end">
                                                                            <i
                                                                                className="fas fa-edit p-1"
                                                                                onClick={() => editStudent(
                                                                                    studentData.id,
                                                                                    studentData.department,
                                                                                    studentData.registeration,
                                                                                    studentData.name,
                                                                                    studentData.email,
                                                                                    studentData.phone,
                                                                                    studentData.password)}
                                                                            >Edit</i>
                                                                            {/* <i className="fas fa-edit p-1">Edit</i> */}
                                                                        </span>
                                                                        <table className="table table-sm table-striped">
                                                                            <tr>
                                                                                <td><b>Department:</b></td>
                                                                                <td>{studentData.dept_name}</td>
                                                                                <td><b>Registeration#</b></td>
                                                                                <td>{studentData.registeration}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>Name:</b></td>
                                                                                <td>{studentData.name}</td>
                                                                                <td><b>Password:</b></td>
                                                                                <td>{studentData.password}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>Email:</b></td>
                                                                                <td>{studentData.email}</td>
                                                                                <td><b>Mobile:</b></td>
                                                                                <td>{studentData.phone}</td>
                                                                            </tr>

                                                                        </table>
                                                                        <h4 className="text-center">All Enrollments</h4>
                                                                        <table className="table table-sm table-striped">
                                                                            {Object.keys(enrollmentData).length > 0 ?
                                                                                <>
                                                                                    {enrollmentData.map((l, index) => (
                                                                                        <>
                                                                                            {(l.session_name !== '-') ?
                                                                                                <tr>
                                                                                                    <td className="text-center text-success" colSpan={5}>
                                                                                                        <b>{l.session_name}</b>
                                                                                                    </td>
                                                                                                </tr> :
                                                                                                <></>}
                                                                                            <tr key={index}>
                                                                                                <td>
                                                                                                    {l.tech_name}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {l.course_name}({l.course_code})
                                                                                                </td>
                                                                                                <td>
                                                                                                    Section {l.section}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {(l.time === 'M') ? "Morning" : (l.time === 'E') ? "Evening" : (l.time === 'W') ? "Weekend" : ""}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {(l.subType === '1') ? "Theory" : (l.subType === '2') ? "Paractical" : (l.subType === '3') ? "Theory /Paractical" : ""}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ))}
                                                                                </> :
                                                                                <tr className="text-center"><i className="text-danger">Not Enrolled in Any Course</i></tr>}
                                                                        </table>
                                                                    </> :
                                                                    <div className="text-center"><i className="text-danger">No Data</i></div>}
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default StudentReport
import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import { Link } from 'react-router-dom'

const Feedback = () => {

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [faculty, setFaculty] = useState("")
    const [department, setDepartment] = useState("")
    const [teacher, setTeacher] = useState("")
    const [semester, setSemester] = useState("")
    const [facultyList, setFacultyList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [teacherList, setTeacherList] = useState([])

    const [semesterList, setSemesterList] = useState([])

    const [assignList, setAssignList] = useState([])

    useEffect(async () => {
        window.scrollTo(0, 0);
        getAssignData()
    }, [])

    async function getAssignData() {
        let assignDataRes = await fetch(window.api + "getAssignData")
        assignDataRes = await assignDataRes.json()
        setFacultyList(assignDataRes.faculty)
        setSemesterList(assignDataRes.semester)

    }

    async function getDepartment(value) {
        setFaculty(value)
        let departmentRes = await fetch(window.api + "getDepartment/" + value)
        departmentRes = await departmentRes.json()
        setDepartmentList(departmentRes.department)
    }

    async function getdata(value) {
        setDepartment(value)
        let dataRes = await fetch(window.api + "getData/" + value)
        dataRes = await dataRes.json()
        setTeacherList(dataRes.teacher)

    }
    async function getStudents(sem) {
        setSemester(sem)
        let stuRes = await fetch(window.api + "getFeedbackData/" + faculty + "/" + department + "/" + teacher + "/" + sem)
        stuRes = await stuRes.json()
        setAssignList(stuRes.student)
    }

    return (
        <>
            <div class="wrapper">

                <Navbar />
                <Sidebar />
                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1 class="m-0">All FeedBacks</h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">All FeedBacks</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section class="content">
                        <div class="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <h3 class="card-title">All FeedBacks</h3>
                                        </div>

                                        <div class="card-body">
                                            {success
                                                ? <div className="alert alert-success" role="alert"> Course Assigned successfully.</div>
                                                : <div></div>
                                            }
                                            {error
                                                ? <div className="alert alert-danger" role="alert">  Course Already assigned to following student.</div>
                                                : <div></div>
                                            }
                                            <div className="row">
                                                <div className="col-sm-3 ms-0">
                                                    <div class="form-group">
                                                        <label>Faculty</label>
                                                        <select value={faculty} onChange={(e) => getDepartment(e.target.value)} class="form-control">
                                                            <option value=''>--Select Faculty--</option>
                                                            {Object.keys(facultyList).length > 0 ? facultyList.map(f => (
                                                                <option value={f.id}>{f.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div class="form-group">
                                                        <label>Department</label>
                                                        <select value={department} onChange={(e) => getdata(e.target.value)} class="form-control">
                                                            <option value=''>--Select Department--</option>
                                                            {Object.keys(departmentList).length > 0 ? departmentList.map(d => (
                                                                <option value={d.id}>{d.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div class="form-group">
                                                        <label>Teacher</label>
                                                        <select value={teacher} onChange={(e) => setTeacher(e.target.value)} class="form-control">
                                                            <option value=''>--Select Teacher--</option>
                                                            {Object.keys(teacherList).length > 0 ? teacherList.map(t => (
                                                                <option value={t.id}>{t.name}({t.designation})</option>
                                                            )) : <></>}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <div class="form-group">
                                                        <label>Semester</label>
                                                        <select value={semester} onChange={(e) => getStudents(e.target.value)} class="form-control">
                                                            <option value=''>--Semester--</option>
                                                            {Object.keys(semesterList).length > 0 ? semesterList.map(s => (
                                                                <option value={s.id}>{s.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr />
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "20px" }}>#</th>
                                                        <th>Registration No.</th>
                                                        <th>Name</th>
                                                        <th>Feedback</th>
                                                        {/* <th>Delete</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(assignList).length > 0 ? assignList.map(a => (
                                                        <tr>
                                                            <td>{a.assigned.id}</td>
                                                            <td>{a.studentData.registeration}</td>
                                                            <td>{a.studentData.name}</td>
                                                            <td>
                                                                <Link to={"/ViewFeedback/"+a.assigned.id} style={{ color: 'blue' }} target="_blank">
                                                                    <i class="fas fa-comment" title='See Feedback'></i>
                                                                </Link>
                                                            </td>
                                                            {/* <td>
                                                                <i onClick={() => delAssign(a.id)} class="fas fa-trash-alt" title='Delete Team'></i>
                                                            </td> */}
                                                        </tr>
                                                    )) :
                                                        <></>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Feedback
import React from 'react'
import { Route, Routes } from "react-router-dom"
import Home from "./components/Home"
import Misc from './components/Misc'
import Post from './components/Post'
import Login from './components/Login'
import Logout from './components/Logout'
import Users from './components/Users'
import Assign from './components/Assign'
import Feedback from './components/Feedback'
import ViewFeedback from './components/ViewFeedback'
import AssignReport from './components/AssignReport'
import StudentReport from './components/StudentReport'
import SelectAll from './components/SelectAll'
import MissingCourse from './components/MissingCourse'
import FeedbackReport from './components/FeedbackReport'
import PerformaEntries from './components/PerformaEntries'
import EnrollReport from './components/EnrollReport'
import Forgot from './components/Forgot'

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Misc" element={<Misc />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/Assign" element={<Assign />} />
        <Route path="/AssignReport" element={<AssignReport />} />
        <Route path="/Post" element={<Post />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Forgot" element={<Forgot />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/ViewFeedback/:p1" element={<ViewFeedback />} />
        <Route path="/StudentReport" element={<StudentReport />} />
        <Route path="/SelectAll" element={<SelectAll />} />
        <Route path="/MissingCourse" element={<MissingCourse />} />
        <Route path="/FeedbackReport" element={<FeedbackReport />} />
        <Route path="/PerformaEntries" element={<PerformaEntries />} />
        <Route path="/EnrollReport" element={<EnrollReport />} />
      </Routes>
    </>
  )
}

export default App;

import React, { useState, useEffect } from "react"

const Question = () => {
    const userType = window.sessionStorage.getItem('type')

    const [qId, setQId] = useState("")
    const [question, setQuestion] = useState("")
    const [qType, setQType] = useState("")
    const [qButtonText, setQButtonText] = useState("Save")
    const [qSuccess, setQSuccess] = useState(false)
    const [qError, setQError] = useState(false)
    const [questionList, setQuestionList] = useState([])


    useEffect(async () => {
        window.scrollTo(0, 0);
        getQuestion()
    }, [])

    async function getQuestion() {
        let questionRes = await fetch(window.api + "getQuestion")
        questionRes = await questionRes.json()
        setQuestionList(questionRes.question)
    }

    async function addQuestion() {
        setQButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', qId)
        formData.append('type', qType)
        formData.append('question', question)

        let result = await fetch(window.api + "addQuestion", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setQButtonText("Save")
            setQSuccess(false)
            setQError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setQError(false)
            setQButtonText("Save")
            setQSuccess(true)
            setQuestion('')
            setQId('')
            getQuestion()
            window.scrollTo(0, 0)
        }
    }

    function updateQuestion(id, type, question) {
        setQId(id)
        setQuestion(question)
        setQType(type)
        setQButtonText("Update")
    }
    async function delQuestion(id) {
        let delRes = await fetch(window.api + "delQuestion/" + id)
        delRes = await delRes.json()
        getQuestion()
    }

    return (
        <div>
            <div class="card card-primary">
                {/* <div class="card-header">
                                            <h3 class="card-title">Add User</h3>
                                        </div> */}
                <div class="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Question</h3>
                                </div>
                                <form>
                                    <div class="card-body">
                                        {qSuccess
                                            ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                            : <div></div>
                                        }
                                        {qError
                                            ? <div className="alert alert-danger" role="alert">  Question Already Added.</div>
                                            : <div></div>
                                        }
                                        <div className="form-group">
                                            <label>Type</label>
                                            <select value={qType} onChange={(e) => setQType(e.target.value)} class="form-control">
                                                <option value=''>--Select Type--</option>
                                                <option value='1'>Course</option>
                                                <option value='2'>Teacher</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Question</label>
                                            <textarea value={question} onChange={(e) => setQuestion(e.target.value)} class="form-control" placeholder="Question"></textarea>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" disabled={!question || !qType || userType == 'H'} onClick={addQuestion} class="btn btn-primary">{qButtonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Questions List</h3>
                                </div>

                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Question</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(questionList).length > 0 ? questionList.map(q => (
                                                <tr>
                                                    <td>{(q.type == 1) ? <>Course</> : <>Teacher</>}</td>
                                                    <td>{q.question}</td>
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateQuestion(q.id, q.type, q.question)} class="fas fa-user-edit" title='Edit Question'></i>
                                                            </>}
                                                    </td>
                                                </tr>
                                            )) :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Question
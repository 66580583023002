import React, { useState, useEffect } from "react"

const Teacher = () => {

    const userType = window.sessionStorage.getItem('type')

    const [tId, setTId] = useState("")
    const [tFaculty, setTFaculty] = useState("")
    const [tDepartment, setTDepartment] = useState("")
    const [tDesignation, setTDesignation] = useState("")
    const [tName, setTName] = useState("")
    const [userName, setUserName] = useState("")
    const [tEmail, setTEmail] = useState("")
    const [tPassword, setTPassword] = useState("")
    const [tButtonText, setTButtonText] = useState("Save")
    const [tSuccess, setTSuccess] = useState(false)
    const [tError, setTError] = useState(false)

    const [tFacultyList, setTFacultyList] = useState([])
    const [department1List, setDepartment1List] = useState([])
    const [teacherList, setTeacherList] = useState([])

    useEffect(async () => {
        window.scrollTo(0, 0);
        getFaculty()
    }, [])

    async function getFaculty() {
        let miscRes = await fetch(window.api + "getFaculty")
        miscRes = await miscRes.json()
        setTFacultyList(miscRes.faculty)
    }


    async function getDepartment1(value) {
        setTFaculty(value)
        setDepartment1List([])
        let departmentRes = await fetch(window.api + "getDepartment/" + value)
        departmentRes = await departmentRes.json()
        setDepartment1List(departmentRes.department)
    }

    async function getTeacher(value) {
        setTDepartment(value)
        let subjectRes = await fetch(window.api + "getTeacher/" + value)
        subjectRes = await subjectRes.json()
        setTeacherList(subjectRes.teacher)
    }

    async function addTeacher() {
        setTButtonText("Saving...")
        const formData = new FormData()
        formData.append('tId', tId)
        formData.append('tFaculty', tFaculty)
        formData.append('tDepartment', tDepartment)
        formData.append('tName', tName)
        formData.append('tdesignation', tDesignation)
        formData.append('userName', userName)
        formData.append('email', tEmail)
        formData.append('password', tPassword)

        let result = await fetch(window.api + "addTeacher", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setTButtonText("Save")
            setTSuccess(false)
            setTError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setTError(false)
            setTButtonText("Save")
            setTSuccess(true)
            setTId('')
            setTName('')
            setTDesignation('')
            setUserName('')
            setTEmail('')
            setTPassword('')
            getTeacher(tDepartment)
            window.scrollTo(0, 0)
        }
    }
    function updateTeacher(id, name, designation, userName, password, email) {
        setTId(id)
        setTName(name)
        setTDesignation(designation)
        setUserName(userName)
        setTEmail(email)
        setTPassword(password)
        setTButtonText("Update")
    }
    async function delSubject(id) {
        let delRes = await fetch(window.api + "delSubject/" + id)
        delRes = await delRes.json()
        getTeacher(tDepartment)
    }

    return (
        <div>
            <div class="card card-primary">
                {/* <div class="card-header">
                                            <h3 class="card-title">Add User</h3>
                                        </div> */}
                <div class="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Teacher</h3>
                                </div>
                                <form>
                                    <div class="card-body">
                                        {tSuccess
                                            ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                            : <div></div>
                                        }
                                        {tError
                                            ? <div className="alert alert-danger" role="alert">  UserName Already Added.</div>
                                            : <div></div>
                                        }
                                        <div className="row">
                                            <div className="col-md-3 p-1">
                                                <select value={tFaculty} onChange={(e) => getDepartment1(e.target.value)} className="form-control form-control-sm">
                                                    <option value=''>--Select Faculty--</option>
                                                    {Object.keys(tFacultyList).length > 0 ? tFacultyList.map(f => (
                                                        <option value={f.id}>{f.name}</option>
                                                    )) : <></>}
                                                </select>
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <select value={tDepartment} onChange={(e) => getTeacher(e.target.value)} className="form-control form-control-sm">
                                                    <option value=''>--Select Department--</option>
                                                    {Object.keys(department1List).length > 0 ? department1List.map(d => (
                                                        <option value={d.id}>{d.name}</option>
                                                    )) : <></>}
                                                </select>
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <select value={tDesignation} onChange={(e) => setTDesignation(e.target.value)} className="form-control form-control-sm">
                                                    <option>--Select Designation--</option>
                                                    <option value="1">Professor</option>
                                                    <option value="2">Associate Professor</option>
                                                    <option value="3">Assistant Professor</option>
                                                    <option value="4">Lecturer</option>
                                                    <option value="5">Visiting</option>
                                                    <option value="6">Subject Expert</option>
                                                    <option value="7">Research Officer</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <input type="text" value={tName} onChange={(e) => setTName(e.target.value)} class="form-control form-control-sm" placeholder="Teacher Name" />
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} class="form-control form-control-sm" placeholder="User Name" />
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <input type="text" value={tEmail} onChange={(e) => setTEmail(e.target.value)} class="form-control form-control-sm" placeholder="Email Address" />
                                            </div>
                                            <div className="col-md-3 p-1">
                                                <input type="password" value={tPassword} onChange={(e) => setTPassword(e.target.value)} class="form-control form-control-sm" placeholder="Password" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" disabled={!tFaculty || !tDepartment || !tName || !userName || !tPassword || userType == 'H'} onClick={addTeacher} class="btn btn-primary">{tButtonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Teachers List</h3>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Designation</th>
                                                {/* <th>Credentials</th> */}
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(teacherList).length > 0 ? teacherList.map(t => (
                                                <tr>
                                                    <td>{t.name}</td>
                                                    <td>{t.email}</td>
                                                    <td>
                                                        {(t.designation == 1) ?
                                                            "Professor" :
                                                            (t.designation == 2) ?
                                                                "Associate Professor" :
                                                                (t.designation == 3) ?
                                                                    "Assistant Professor" :
                                                                    (t.designation == 4) ?
                                                                        "Lecturer" :
                                                                        (t.designation == 5) ?
                                                                            "Visiting" :
                                                                            (t.designation == 6) ?
                                                                                "Subject Expert" :
                                                                                (t.designation == 7) ?
                                                                                    "Research Officer" :
                                                                                    ""
                                                        }
                                                    </td>
                                                    {/* <td>
                                                        <b>Username:</b> {t.username}<br />
                                                        <b>Password:</b> {t.password}
                                                    </td> */}
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateTeacher(t.id, t.name, t.designation, t.username, t.password, t.email)} class="fas fa-user-edit" title='Edit Department'></i>
                                                            </>}
                                                    </td>
                                                </tr>
                                            )) :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Teacher
import React, { useState, useEffect, useRef } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { pdfFromReact } from "generate-pdf-from-react-html";
import { PDFExport } from "@progress/kendo-react-pdf";


const FeedbackReport = () => {
    const userPassword = window.sessionStorage.getItem('password')
    const userType = window.sessionStorage.getItem('type')
    const userId = window.sessionStorage.getItem('id')
    const userFaculty = window.sessionStorage.getItem('userFaculty')
    const userDepartment = window.sessionStorage.getItem('userDepartment')

    const [faculty, setFaculty] = useState("")
    const [department, setDepartment] = useState("")
    const [selectedSession, setSelectedSession] = useState("")
    const [sessionName, setSessionName] = useState("")
    const [session, setSession] = useState("")
    const [sessionStatus, setSessionStatus] = useState("")
    const [facultyList, setFacultyList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [assignList, setAssignList] = useState([])
    const [teacherAssignList, setTeacherAssignList] = useState([])
    const [enrolledstudentList, setEnrolledStudentList] = useState([])
    const [sessionList, setSessionList] = useState([])
    const [isChecked, setIsChecked] = useState([])
    const [totalEnrolled, setTotalEnrolled] = useState("")

    const [enrFaculty, setEnrFaculty] = useState("")
    const [enrDepartment, setEnrDepartment] = useState("")
    const [enrSession, setEnrSession] = useState("")
    const [enrTeacher, setEnrTeacher] = useState("")
    const [enrSubject, setEnrSubject] = useState("")
    const [percentage, setPercentage] = useState("1")

    const [loader, setLoader] = useState(false)
    const [getStudentLoader, setGetStudentLoader] = useState(false)
    const [delLoader, setDelLoader] = useState(false)
    const [show, setShow] = useState(false);

    const [editStatus, setEditStatus] = useState(false);

    const closeStudents = () => {
        setShow(false); setEnrFaculty(""); setEnrDepartment(); setEnrSession(); setEnrTeacher(); setEnrSubject();
        if (editStatus) {
            if (userType === 'A') {
                getAssignReport(department)
            } else if (userType === 'D') {
                getDepartmentAssignReport(userFaculty, userId, session)
            } else if (userType === 'T') {
                getTeacherAssignReport(userFaculty, userDepartment, userId, session)
            }
        }

    }
    const showStudents = () => setShow(true);


    useEffect(async () => {
        window.scrollTo(0, 0);
        getAssignData()
    }, [])

    async function getAssignData() {
        let assignDataRes = await fetch(window.api + "getAssignData")
        assignDataRes = await assignDataRes.json()
        setSession(assignDataRes.session.id)
        setSessionName(assignDataRes.session.name)
        setSessionStatus(assignDataRes.session.Locked)
        setFacultyList(assignDataRes.faculty)
        setSessionList(assignDataRes.session_list)
        if (userType === 'D') {
            setFaculty(userFaculty)
            getDepartment(userFaculty)
            setDepartment(userId)
            getDepartmentAssignReport(userFaculty, userId, assignDataRes.session.id)
        } else if (userType === 'T') {
            getTeacherAssignReport(userFaculty, userDepartment, userId, assignDataRes.session.id)
        }
    }

    async function getAssignReport(dept) {
        setDepartment(dept)
        setLoader(true)
        const formData = new FormData()
        formData.append('faculty', faculty)
        formData.append('department', dept)
        formData.append('session', selectedSession)

        let result = await fetch(window.api + "getAssignReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        setLoader(false)
        if (result) {
            setAssignList(result.assigned_teacher)
            setTotalEnrolled(result.count_subjects)
            setEditStatus(false)
        }
    }

    async function getDepartmentAssignReport(fac, dept, sess) {
        setLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('session', sess)

        let result = await fetch(window.api + "getAssignReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        setLoader(false)
        if (result) {
            setAssignList(result.assigned_teacher)
            setEditStatus(false)
        }
    }

    async function getTeacherAssignReport(fac, dept, tech, sess) {

        setLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('session', sess)
        formData.append('teacher', tech)

        let result = await fetch(window.api + "getTeacherAssignReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        setLoader(false)
        if (result) {
            setTeacherAssignList(result.assigned_teacher)
            setEditStatus(false)
        }
    }

    async function getDepartment(value) {
        setFaculty(value)
        let departmentRes = await fetch(window.api + "getDepartment/" + value)
        departmentRes = await departmentRes.json()
        setDepartmentList(departmentRes.department)
    }

    async function getStudent(fac, dept, sess, tech, subj, sect, time) {

        setEnrFaculty(fac); setEnrDepartment(dept); setEnrSession(sess); setEnrTeacher(tech); setEnrSubject(subj);
        setShow(true)
        setGetStudentLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('session', sess)
        formData.append('teacher', tech)
        formData.append('subject', subj)
        formData.append('section', sect)
        formData.append('time', time)

        let result = await fetch(window.api + "getEnrolledStudents", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        setGetStudentLoader(false)
        if (result) {
            setEnrolledStudentList(result.enrolled_students)
        }
    }
    const handlecheckbox = (e) => {
        const { value, checked } = e.target
        if (checked) {
            setIsChecked([...isChecked, value])
        } else {
            setIsChecked(isChecked.filter((e) => e !== value))
        }
    }

    const allDelete = async () => {
        setDelLoader(true)
        const formData = new FormData()
        formData.append('assignList', JSON.stringify(isChecked))

        let result = await fetch(window.api + "deleteAssign", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result.success) {
            setEditStatus(true)
            setDelLoader(false)
            getStudent(enrFaculty, enrDepartment, enrSession, enrTeacher, enrSubject)
        }
    }

    const deleteAllEnrollment = async (faculty, department, session, teacher, subject, section, time) => {

        const formData = new FormData()
        formData.append('faculty', faculty)
        formData.append('department', department)
        formData.append('session', session)
        formData.append('teacher', teacher)
        formData.append('subject', subject)
        formData.append('section', section)
        formData.append('time', time)

        let result = await fetch(window.api + "deleteAllEnrollment", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result.success) {

            if (userType === 'A') {
                getAssignReport(department)
            } else if (userType === 'D') {
                getDepartmentAssignReport(userFaculty, userId, session)
            } else if (userType === 'T') {
                getTeacherAssignReport(userFaculty, userDepartment, userId, session)
            }
        }
    }

    const [agNo, setAgNo] = useState("")
    const [assignId, setAssignId] = useState("")
    const [reason, setReason] = useState("")
    const [stopButtonText, setStopButtonText] = useState("Save")

    const [reasonModal, setReasonModal] = useState(false)
    const [reasonLoader, setReasonLoader] = useState(false)
    const [enableLoader, setEnaleLoader] = useState(false)

    const closeReasonModal = () => {
        setAssignId("")
        setAgNo("")
        setReason("")
        setReasonModal(false)
        getStudent(enrFaculty, enrDepartment, enrSession, enrTeacher, enrSubject)
    }
    const showReasonModal = (assignId, agNo) => {
        setAssignId(assignId)
        setAgNo(agNo)
        setReasonModal(true)
    }
    const stopAssign = async () => {
        setReasonLoader(true)
        setStopButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', assignId)
        formData.append('reason', reason)

        let result = await fetch(window.api + "stopAssign", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result.success) {
            setReasonLoader(false)
            setStopButtonText("Save")
            closeReasonModal()
        }
    }

    const enableAssign = async (assignId) => {
        setAssignId(assignId)
        setEnaleLoader(true)
        const formData = new FormData()
        formData.append('id', assignId)

        let result = await fetch(window.api + "enableAssign", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result.success) {
            setEnaleLoader(false)
            setAssignId("")
            getStudent(enrFaculty, enrDepartment, enrSession, enrTeacher, enrSubject)
        }
    }

    const [reportData, setReportData] = useState([])

    const [rStaAvrg, setRStaAvrg] = useState("")
    const [rAAvrg, setRAAvrg] = useState("")
    const [rSmwaAvrg, setRSmwaAvrg] = useState("")
    const [rDaAvrg, setRDaAvrg] = useState("")
    const [rStdaAvrg, setRStdaAvrg] = useState("")
    const [rStaAgree, setRStaAgree] = useState("")
    const [rAAgree, setRAAgree] = useState("")
    const [rSmwaAgree, setRSmwaAgree] = useState("")
    const [rDaAgree, setRDaAgree] = useState("")
    const [rStdaAgree, setRStdaAgree] = useState("")
    const [rStaMarks, setRStaMarks] = useState("")
    const [rAMarks, setRAMarks] = useState("")
    const [rSmwaMarks, setRSmwaMarks] = useState("")
    const [rDaMarks, setRDaMarks] = useState("")
    const [rStdaMarks, setRStdaMarks] = useState("")
    const [rStaObtMarks, setRStaObtMarks] = useState("")
    const [rAObtMarks, setRAObtMarks] = useState("")
    const [rSmwaObtMarks, setRSmwaObtMarks] = useState("")
    const [rDaObtMarks, setRDaObtMarks] = useState("")
    const [rStdaObtMarks, setRStdaObtMarks] = useState("")
    const [totalObMarks, setTotalObMarks] = useState("")
    const [percentageSummary, setPercentageSummary] = useState("")

    const [reportModal, setReportModal] = useState(false)
    const [reportLoader, setReportLoader] = useState(false)
    const [enableRepLoader, setEnaleRepLoader] = useState(false)

    const closeReportModal = () => {
        setReportData([])
        setRStaAvrg("")
        setRAAvrg("")
        setRSmwaAvrg("")
        setRDaAvrg("")
        setRStdaAvrg("")
        setRStaAgree("")
        setRAAgree("")
        setRSmwaAgree("")
        setRDaAgree("")
        setRStdaAgree("")
        setRStaMarks("")
        setRAMarks("")
        setRSmwaMarks("")
        setRDaMarks("")
        setRStdaMarks("")
        setRStaObtMarks("")
        setRAObtMarks("")
        setRSmwaObtMarks("")
        setRDaObtMarks("")
        setRStdaObtMarks("")
        setTotalObMarks("")
        setPercentageSummary("")

        setReportModal(false)
    }


    async function getFeedbackReport(fac, dept, sess, tech, subj, sect, time, subType) {
        setReportModal(true)
        setReportLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('sess_id', sess)
        formData.append('teach_id', tech)
        formData.append('sub_id', subj)
        formData.append('sec_id', sect)
        formData.append('time_id', time)
        formData.append('sub_type', subType)

        let result = await fetch(window.api + "getEvaluationReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result.evaluation_report) {
            setReportData(result.evaluation_report)
            setRStaAvrg(result.r_sta_avrg)
            setRAAvrg(result.r_a_avrg)
            setRSmwaAvrg(result.r_smwa_avrg)
            setRDaAvrg(result.r_da_avrg)
            setRStdaAvrg(result.r_stda_avrg)
            setRStaAgree(result.r_sta_agree)
            setRAAgree(result.r_a_agree)
            setRSmwaAgree(result.r_smwa_agree)
            setRDaAgree(result.r_da_agree)
            setRStdaAgree(result.r_stda_agree)
            setRStaMarks(result.r_sta_marks)
            setRAMarks(result.r_a_marks)
            setRSmwaMarks(result.r_smwa_marks)
            setRDaMarks(result.r_da_marks)
            setRStdaMarks(result.r_stda_marks)
            setRStaObtMarks(result.r_sta_obt_marks)
            setRAObtMarks(result.r_a_obt_marks)
            setRSmwaObtMarks(result.r_smwa_obt_marks)
            setRDaObtMarks(result.r_da_obt_marks)
            setRStdaObtMarks(result.r_stda_obt_marks)
            setTotalObMarks(result.r_total_ob_marks)
            setPercentageSummary(result.r_percentage_summary)

            setReportLoader(false)
        }
    }

    // Get Percentage Report

    const [perReportData, setPerReportData] = useState([])
    const [perCommentsData, setPerCommentsData] = useState([])
    const [perTotalScore, setPerTotalScore] = useState("")

    const [perReportModal, setPerReportModal] = useState(false)
    const [perReportLoader, setPerReportLoader] = useState(false)

    const closePerReportModal = () => {
        setPerReportData([])
        setPerReportModal(false)
    }


    async function getPercentageReport(fac, dept, sess, tech, subj, sect, time, subType) {
        setPerReportModal(true)
        setPerReportLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('sess_id', sess)
        formData.append('teach_id', tech)
        formData.append('sub_id', subj)
        formData.append('sec_id', sect)
        formData.append('time_id', time)
        formData.append('sub_type', subType)

        let result = await fetch(window.api + "getPercentageReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result.all_report) {
            setPerReportData(result.all_report)
            setPerTotalScore(result.total_percentage)
            setPerCommentsData(result.comments)
            setPerReportLoader(false)
        }
    }

    const pdfExportComponent = React.useRef(null);

    return (
        <div>
            <div className="wrapper">
                <Navbar />
                <Sidebar />
                <div className="content-wrapper">
                    <Modal show={show} onHide={closeStudents}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enrolled Students</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {(isChecked.length > 0) ?
                                            <>
                                                <button 
                                                type="button" 
                                                className="btn btn-success" 
                                                onClick={allDelete}
                                                disabled={userType == 'H'}
                                                >
                                                    {delLoader ? "Deleting.." : "Delete"}
                                                </button>
                                            </> :
                                            <></>}
                                    </div>
                                    {getStudentLoader ?
                                        <>
                                            <i className="text-center">Fetching Record Please Wait...</i>
                                        </> :
                                        <>
                                            {Object.keys(enrolledstudentList).length > 0 ?
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Reg #</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {enrolledstudentList.map((s, index) => (
                                                            <tr key={index}>
                                                                <td className="text-center">
                                                                    {(userType === 'A' || sessionStatus == 0) ?
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={s.id}
                                                                                checked={s.isChecked}
                                                                                onChange={(e) => handlecheckbox(e)}
                                                                            />
                                                                        </> :
                                                                        <></>
                                                                    }
                                                                </td>
                                                                <td>{s.agNo}</td>
                                                                <td>
                                                                    {(s.feedback_status == 0) ?
                                                                        <>
                                                                            {(userType === 'A' || sessionStatus == 0) ?
                                                                                <>

                                                                                    {(s.status == 1) ?
                                                                                        <>
                                                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => showReasonModal(s.id, s.agNo)} disabled={userType == 'H'}>Stop</button>
                                                                                        </> :
                                                                                        <>
                                                                                            <button type="button" className="btn btn-success btn-sm" onClick={() => enableAssign(s.id)} disabled={userType == 'H'}>
                                                                                                {(assignId == s.id && enableLoader) ? 'Enabling..' : 'Enable'}
                                                                                            </button>

                                                                                        </>}
                                                                                </> :
                                                                                <><i className="text-secondary">No Action</i></>}
                                                                        </> :
                                                                        <>
                                                                            <i className="text-success">Feedback Submitted</i>
                                                                        </>}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table> :
                                                <div className="text-center"><i className="text-danger">No Data</i></div>}
                                        </>}

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={reasonModal} onHide={closeReasonModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{agNo}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as="textarea" rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={stopAssign} disabled={!reason || !assignId}>
                                {reasonLoader ? stopButtonText : stopButtonText}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={reportModal} size="xl" onHide={closeReportModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>View Report</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    {(reportLoader) ?
                                        <><p className="text-info">Fetching Record Please Wait..</p></> :
                                        <>
                                            {Object.keys(reportData).length > 0 ?
                                                <>
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th>Question</th>
                                                                <th>S-Agree</th>
                                                                <th>Agree</th>
                                                                <th>SW-Agree</th>
                                                                <th>Disagree</th>
                                                                <th>S-Disagree</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {reportData.map((r, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td>{r.question}</td>
                                                                        <td className="text-center">{r.Strongly_Agree}</td>
                                                                        <td className="text-center">{r.Agree}</td>
                                                                        <td className="text-center">{r.Somewhat_Else}</td>
                                                                        <td className="text-center">{r.Disagree}</td>
                                                                        <td className="text-center">{r.Strongly_Disagree}</td>
                                                                    </tr>
                                                                </>
                                                            ))}

                                                            <tr>
                                                                <td>
                                                                    <div className="row">
                                                                        <div className="col-md-10">
                                                                            <div className="text-center mt-4">
                                                                                <p className="text-danger"> Summary of Current Score <br />{percentageSummary} %</p>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row mb-0">
                                                                                <div className="col-md-4">Total Marks</div>
                                                                                <div className="col-md-2">25</div>
                                                                                <div className="col-md-4">Obtained Marks</div>
                                                                                <div className="col-md-2">{totalObMarks}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 border-left">
                                                                            <p className="float-right">Avarage</p>
                                                                            <p className="float-right">% Agree</p>
                                                                            <p className="float-right">Marks</p>
                                                                            <p className="float-right">Obtained</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <p>{rStaAvrg}</p>
                                                                    <p>{rStaAgree}</p>
                                                                    <p>{rStaMarks}</p>
                                                                    <p>{rStaObtMarks}</p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <p>{rAAvrg}</p>
                                                                    <p>{rAAgree}</p>
                                                                    <p>{rAMarks}</p>
                                                                    <p>{rAObtMarks}</p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <p>{rSmwaAvrg}</p>
                                                                    <p>{rSmwaAgree}</p>
                                                                    <p>{rSmwaMarks}</p>
                                                                    <p>{rSmwaObtMarks}</p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <p>{rDaAvrg}</p>
                                                                    <p>{rDaAgree}</p>
                                                                    <p>{rDaMarks}</p>
                                                                    <p>{rDaObtMarks}</p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <p>{rStdaAvrg}</p>
                                                                    <p>{rStdaAgree}</p>
                                                                    <p>{rStdaMarks}</p>
                                                                    <p>{rStdaObtMarks}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </> :
                                                <>
                                                    <p className="text-danger">No Record Found</p>
                                                </>}
                                        </>}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={closeReportModal} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={perReportModal} size="xl" onHide={closePerReportModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>View Report</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    {(perReportLoader) ?
                                        <><p className="text-info">Fetching Record Please Wait..</p></> :
                                        <>
                                            {Object.keys(perReportData).length > 0 ?
                                                <>
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th>Question</th>
                                                                <th>Percentage</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {perReportData.map((r, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td>{r.question}</td>
                                                                        <td className="text-center">{r.Percentage_Vlaue} %</td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                            <tr className="text-info">
                                                                <td class="text-center" rowspan="3" colspan="3"><span class="text-center" style={{ fontSize: "18px" }}>Summary of Current Course</span><br />{perTotalScore} % </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <h2 className="text-center">Comments</h2>
                                                    {perCommentsData.map((c, index) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td>{c.Teacher_Comments}</td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </> :
                                                <>
                                                    <p className="text-danger">No Record Found</p>
                                                </>}
                                        </>}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={closePerReportModal} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Feedback Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Feedback Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Course Enrollment for {sessionName}</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-2 p-1">
                                                    <select value={selectedSession} onChange={(e) => setSelectedSession(e.target.value)} className="form-control form-control-sm">
                                                        <option>--Select Session--</option>
                                                        {Object.keys(sessionList).length > 0 ? sessionList.map((s, index) => (
                                                            <option key={index} value={s.id}>{s.name}</option>
                                                        )) : <></>}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3 p-1">
                                                    <select value={faculty} onChange={(e) => getDepartment(e.target.value)} className="form-control form-control-sm">
                                                        <option value=''>--Select Faculty--</option>
                                                        {Object.keys(facultyList).length > 0 ? facultyList.map((f, index) => (
                                                            <option key={index} value={f.id}>{f.name}</option>
                                                        )) : <></>}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3 p-1">
                                                    <select value={department} onChange={(e) => getAssignReport(e.target.value)} className="form-control form-control-sm">
                                                        <option value=''>--Select Department--</option>
                                                        {Object.keys(departmentList).length > 0 ? departmentList.map((d, index) => (
                                                            <option key={index} value={d.id}>{d.name}</option>
                                                        )) : <></>}
                                                    </select>
                                                </div>

                                                {Object.keys(assignList).length > 0 ?
                                                    <>
                                                        <div className="col-sm-2 p-1">
                                                            <select className="form-control form-control-sm" value={percentage} onChange={(e) => setPercentage(e.target.value)}>
                                                                <option value="1">All</option>
                                                                <option value="2">Less Than 80 %</option>
                                                                <option value="3">Less Than 20 %</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-2 p-1">
                                                            <button
                                                                className="btn btn-info btn-sm"
                                                                onClick={() => {
                                                                    if (pdfExportComponent.current) {
                                                                        pdfExportComponent.current.save();
                                                                    }
                                                                }}
                                                            >
                                                                Export PDF
                                                            </button>
                                                        </div>
                                                    </> :
                                                    <></>}
                                            </div>
                                            <div className="row">
                                                {(userType === 'A' && faculty && department) ?
                                                    <>
                                                        <div className="col-sm-2">
                                                            <span className="text-success"><b>Faculty:</b> {faculty}</span>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <span className="text-success"><b>Department:</b> {department}</span>
                                                        </div>
                                                    </> :
                                                    <></>}
                                            </div>
                                            <PDFExport paperSize="A4" margin="0.5cm" ref={pdfExportComponent}>
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        {(loader) ?
                                                            <div className="text-center">
                                                                <i>Fetching Data Please Wait...</i>
                                                            </div> :
                                                            <>
                                                                {Object.keys(assignList).length > 0 ?
                                                                    <>
                                                                        <div className="row element-to-print mt-1">
                                                                            <div className="col-sm-12">
                                                                                <table className="table table-sm">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Course</th>
                                                                                            <th>Stu#</th>
                                                                                            <th>Submitted</th>
                                                                                            <th>Submitted %</th>
                                                                                            <th>Report</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {assignList.map((a, index) => (
                                                                                            <>
                                                                                                <tr key={index}>
                                                                                                    <td className="text-success text-center font-weight-bold" colSpan={5}>{a.teacher_name} {(userType === 'A') ? <>({a.teacher_id})</> : <></>}</td>
                                                                                                </tr>
                                                                                                {a.assigned_data.map(l => (
                                                                                                    (percentage == 1) ?
                                                                                                        <>
                                                                                                            {(l.enrolled > 19 || l.enrolled == 19) ?
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            {l.subject_code} {(userType === 'A') ? <>({l.subject_id})</> : <></>}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <i className="text-info"
                                                                                                                                onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>{l.enrolled}</i>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {l.feedback_status}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {l.feedback_percentage} %
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {/* {(l.feedback_status >= 19) ?
                                                                                                                                <> */}
                                                                                                                                    <i className="text-info font-weight-bold"
                                                                                                                                        onClick={() => getFeedbackReport(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>View</i>
                                                                                                                                {/* </> :
                                                                                                                                <>
                                                                                                                                    <i className="text-danger">Less Than 19</i>
                                                                                                                                </>} */}

                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </> :
                                                                                                                <></>}
                                                                                                        </> :
                                                                                                        (percentage == 2) ?
                                                                                                            <>
                                                                                                                {((l.feedback_percentage === '80' || l.feedback_percentage < '80') && (l.enrolled > 19 || l.enrolled == 19)) ?
                                                                                                                    <>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                {l.subject_code}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <i className="text-info"
                                                                                                                                    onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>{l.enrolled}</i>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <i className="text-success font-weight-bold"
                                                                                                                                    onClick={() => getFeedbackReport(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>{l.feedback_status}</i>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {l.feedback_percentage} %
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {(l.feedback_status >= 19) ?
                                                                                                                                    <>
                                                                                                                                        <i className="text-info font-weight-bold"
                                                                                                                                            onClick={() => getPercentageReport(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>View</i>
                                                                                                                                    </> :
                                                                                                                                    <>
                                                                                                                                        <i className="text-danger">Less Than 19</i>
                                                                                                                                    </>}

                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </> :
                                                                                                                    <></>}
                                                                                                            </> :
                                                                                                            (percentage == 3) ?
                                                                                                                <>
                                                                                                                    {((l.feedback_percentage === '20' || l.feedback_percentage < '20') && (l.enrolled > 19 || l.enrolled == 19)) ?
                                                                                                                        <>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    {l.subject_code}
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <i className="text-info"
                                                                                                                                        onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>{l.enrolled}</i>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <i className="text-success font-weight-bold"
                                                                                                                                        onClick={() => getFeedbackReport(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>{l.feedback_status}</i>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    {l.feedback_percentage} %
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    {(l.feedback_status >= 19) ?
                                                                                                                                        <>
                                                                                                                                            <i className="text-info font-weight-bold"
                                                                                                                                                onClick={() => getPercentageReport(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType)}>View</i>
                                                                                                                                        </> :
                                                                                                                                        <>
                                                                                                                                            <i className="text-danger">Less Than 19</i>
                                                                                                                                        </>}

                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </> :
                                                                                                                        <></>}
                                                                                                                </> :
                                                                                                                <></>
                                                                                                ))}
                                                                                                {/* <tr key={index}>
                                                                                                    <td>{a.teacher_name}</td>

                                                                                                    <td>
                                                                                                        {a.assigned_data.map(l => (
                                                                                                            (percentage == 1) ?
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            {l.subject_code}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <i className="text-info"
                                                                                                                                onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time)}>{l.enrolled}</i>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <i className="text-success font-weight-bold">{l.feedback_status}</i>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {l.feedback_percentage} %
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </> :
                                                                                                                (percentage == 2) ?
                                                                                                                    <>
                                                                                                                        {(l.feedback_percentage === '80' || l.feedback_percentage > '80') ?
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        {l.subject_code}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        <i className="text-info"
                                                                                                                                            onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time)}>{l.enrolled}</i>
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        <i className="text-success font-weight-bold">{l.feedback_status}</i>
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {l.feedback_percentage} %
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </> :
                                                                                                                            <></>}
                                                                                                                    </> :
                                                                                                                    (percentage == 3) ?
                                                                                                                        <>
                                                                                                                            {(l.feedback_percentage === '20' || l.feedback_percentage < '20') ?
                                                                                                                                <>
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            {l.subject_code}
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <i className="text-info"
                                                                                                                                                onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time)}>{l.enrolled}</i>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <i className="text-success font-weight-bold">{l.feedback_status}</i>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            {l.feedback_percentage} %
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </> :
                                                                                                                                <></>}
                                                                                                                        </> :
                                                                                                                        <></>
                                                                                                        ))}
                                                                                                    </td>
                                                                                                </tr> */}
                                                                                            </>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </> :
                                                                    <div className="text-center"><i className="text-danger"></i></div>}
                                                            </>}
                                                    </div>
                                                </div>
                                            </PDFExport>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default FeedbackReport
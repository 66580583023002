import React, { useState, useEffect } from "react"

const FacultyResume = () => {
    const userType = window.sessionStorage.getItem('type')

    const [dId, setDId] = useState("")
    const [faculty, setFaculty] = useState("")
    const [dName, setDName] = useState("")
    const [dEmail, setDEmail] = useState("")
    const [experience, setExperience] = useState("")
    const [dPassword, setDPassword] = useState("")
    const [dButtonText, setDButtonText] = useState("Save")
    const [dSuccess, setDSuccess] = useState(false)
    const [dError, setDError] = useState(false)

    const [semesterList, setSemesterList] = useState([])
    const [departmentList, setDepartmentList] = useState([])

    useEffect(async () => {
        window.scrollTo(0, 0);
        getSession()
    }, [])

    async function getSession() {
        let miscRes = await fetch(window.api + "getSession")
        miscRes = await miscRes.json()

        setSemesterList(miscRes.session)
    }

    async function getDepartment(value) {
        setFaculty(value)
        setDepartmentList([])
        let departmentRes = await fetch(window.api + "getDepartment/" + value)
        departmentRes = await departmentRes.json()
        setDepartmentList(departmentRes.department)
    }

    async function addDepartment() {
        setDButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', dId)
        formData.append('faculty', faculty)
        formData.append('dName', dName)
        formData.append('dEmail', dEmail)
        formData.append('dPassword', dPassword)

        let result = await fetch(window.api + "addDepartment", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setDButtonText("Save")
            setDSuccess(false)
            setDError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setDError(false)
            setDButtonText("Save")
            setDSuccess(true)
            setDId('')
            setDName('')
            setFaculty('')
            setDEmail('')
            setDPassword('')
            getDepartment(faculty)
            window.scrollTo(0, 0)
        }
    }

    function updateDepartment(id, name, faculty, email, password) {
        setDId(id)
        setDName(name)
        setFaculty(faculty)
        setDEmail(email)
        setDPassword(password)
        setDButtonText("Update")
    }

    async function delDepartment(id) {
        let delRes = await fetch(window.api + "delDepartment/" + id)
        delRes = await delRes.json()
        getDepartment(faculty)
    }
    return (
        <div>
            <div class="card card-primary">
                {/* <div class="card-header">
                                            <h3 class="card-title">Add User</h3>
                                        </div> */}
                <div class="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Faculty Resume</h3>
                                </div>
                                <form>
                                    <div class="card-body">
                                        {dSuccess
                                            ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                            : <div></div>
                                        }
                                        {dError
                                            ? <div className="alert alert-danger" role="alert"> Already Added.</div>
                                            : <div></div>
                                        }
                                        <div className="row">
                                            <div className="col-md-2 p-1">
                                                <select value={faculty} onChange={(e) => getDepartment(e.target.value)} className="form-control form-control-sm">
                                                    <option value=''>--Select Session--</option>
                                                    {Object.keys(semesterList).length > 0 ? semesterList.map(f => (
                                                        <option value={f.id}>{f.name}</option>
                                                    )) : <></>}
                                                </select>

                                            </div>
                                            <div className="col-md-10 p-1">
                                                <input type="text" value={dName} onChange={(e) => setDName(e.target.value)} class="form-control form-control-sm" placeholder="Personal (May include address(s) and phone number(s) and other personal information that the candidate feels is pertinent)" />

                                            </div>
                                            <div className="col-md-12 p-1">
                                                <textarea value={experience} onChange={(e) => setExperience(e.target.value)} className="form-control form-control-sm" placeholder="Experience (List current appointment first, each entry as follows: Date, Title, Institution.)"></textarea>

                                            </div>
                                            <div className="col-md-3 p-1">
                                                <input type="password" value={dPassword} onChange={(e) => setDPassword(e.target.value)} class="form-control form-control-sm" placeholder="Password" />

                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" disabled={!faculty || !dName || !dEmail || !dPassword || userType == 'H'} onClick={addDepartment} class="btn btn-primary">{dButtonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Departments List</h3>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Password</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(semesterList).length > 0 ? semesterList.map(d => (
                                                <tr>
                                                    <td>{d.name}</td>
                                                    <td>{d.email}</td>
                                                    <td>{d.password}</td>
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateDepartment(d.id, d.name, d.faculty, d.email, d.password)} class="fas fa-user-edit" title='Edit Department'></i>
                                                            </>}
                                                    </td>
                                                </tr>
                                            )) :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacultyResume
import React from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import Faculty from "./misccomponents/Faculty"
import Department from "./misccomponents/Department"
import Subject from "./misccomponents/Subject"
import Semester from "./misccomponents/Semester"
import Session from "./misccomponents/Session"
import Question from "./misccomponents/Question"
import Teacher from "./misccomponents/Teacher"

const Misc = () => {

    return (
        <>
            <div class="wrapper">
                {/* <div class="preloader flex-column justify-content-center align-items-center">
                    <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
                </div> */}
                <Navbar />
                <Sidebar />
                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1 class="m-0">Misc Entries</h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Misc Entries</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="content">
                        <div class="container-fluid">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="faculty-tab" data-bs-toggle="tab" data-bs-target="#faculty" type="button" role="tab" aria-controls="faculty" aria-selected="true">Faculties</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="department-tab" data-bs-toggle="tab" data-bs-target="#department" type="button" role="tab" aria-controls="department" aria-selected="false">Departments</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="teacher-tab" data-bs-toggle="tab" data-bs-target="#teacher" type="button" role="tab" aria-controls="teacher" aria-selected="false">Teachers</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="subject-tab" data-bs-toggle="tab" data-bs-target="#subject" type="button" role="tab" aria-controls="subject" aria-selected="false">Courses</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="semester-tab" data-bs-toggle="tab" data-bs-target="#semester" type="button" role="tab" aria-controls="semester" aria-selected="false">Semesters</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="session-tab" data-bs-toggle="tab" data-bs-target="#session" type="button" role="tab" aria-controls="session" aria-selected="false">Sessions</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="question-tab" data-bs-toggle="tab" data-bs-target="#question" type="button" role="tab" aria-controls="question" aria-selected="false">Questionnaires</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="faculty" role="tabpanel" aria-labelledby="faculty-tab">
                                    <Faculty />
                                </div>
                                <div class="tab-pane fade" id="department" role="tabpanel" aria-labelledby="department-tab">
                                   <Department />
                                </div>
                                <div class="tab-pane fade" id="teacher" role="tabpanel" aria-labelledby="teacher-tab">
                                   <Teacher />
                                </div>
                                <div class="tab-pane fade" id="subject" role="tabpanel" aria-labelledby="subject-tab">
                                   <Subject />
                                </div>
                                <div class="tab-pane fade" id="semester" role="tabpanel" aria-labelledby="semester-tab">
                                    <Semester />
                                </div>
                                <div class="tab-pane fade" id="session" role="tabpanel" aria-labelledby="session-tab">
                                    <Session />
                                </div>
                                <div class="tab-pane fade" id="question" role="tabpanel" aria-labelledby="question-tab">
                                    <Question />
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Misc
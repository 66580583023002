import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import InputMask from 'react-input-mask'
import formatFile from './files/format.csv'
import Select from 'react-select'

const Assign = () => {
    const userPassword = window.sessionStorage.getItem('password')
    const userType = window.sessionStorage.getItem('type')
    const userId = window.sessionStorage.getItem('id')
    const userFaculty = window.sessionStorage.getItem('userFaculty')
    const userDepartment = window.sessionStorage.getItem('userDepartment')

    const [id, setId] = useState("")

    const [buttonText, setButtonText] = useState("Save")
    const [successText, setSuccessText] = useState("")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [faculty, setFaculty] = useState("")
    const [department, setDepartment] = useState("")
    const [narration, setNarration] = useState();
    const [rDepartment, setRDepartment] = useState("")
    const [teacher, setTeacher] = useState("")
    const [subject, setSubject] = useState("")
    const [semester, setSemester] = useState(0)
    const [sessionName, setSessionName] = useState("")
    const [session, setSession] = useState("")
    const [sessionStatus, setSessionStatus] = useState("")
    const [section, setSection] = useState("A")
    const [time, setTime] = useState("M")
    const [subType, setSubType] = useState("1")
    const [uploadType, setUploadType] = useState("1")
    const [agNo, setAgNo] = useState("")
    const [facultyList, setFacultyList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [teacherList, setTeacherList] = useState([])
    const [subjectList, setSubjectList] = useState([])
    const [semesterList, setSemesterList] = useState([])
    const [sessionList, setSessionList] = useState([])
    const [assignList, setAssignList] = useState([])

    const [allDepartmentList, setAllDepartmentList] = useState([])

    const [file, setFile] = useState("")

    const [viewButtonText, setViewButtonText] = useState("View List")


    useEffect(async () => {
        window.scrollTo(0, 0);
        getAssignData()
        if (userType === 'T') {
            getDepartment(userFaculty)
            setTeacher(userId)
            setDepartment(userDepartment)
            setRDepartment(userDepartment)
            getdata(userDepartment)
        } else if (userType === 'D') {
            getDepartment(userFaculty)
            getTeacher(userId)
            setDepartment(userId)
            setRDepartment(userId)
            getdata(userId)
        }
    }, [])

    async function getAssignData() {

        let assignDataRes = await fetch(window.api + "getAssignData")
        assignDataRes = await assignDataRes.json()
        setSession(assignDataRes.session.id)
        setSessionName(assignDataRes.session.name)
        setSessionStatus(assignDataRes.session.Locked)
        setSessionList(assignDataRes.session)
        setFaculty(userFaculty)
        setFacultyList(assignDataRes.faculty)
    }

    async function getDepartment(value) {
        setFaculty(value)
        let departmentRes = await fetch(window.api + "getDepartmentAssign/" + value)
        departmentRes = await departmentRes.json()
        setDepartmentList(departmentRes.department)
        // setAllDepartmentList(departmentRes.all_departments)
        departmentRes.all_departments.map(d => (
            allDepartmentList.push({
                value: d.id,
                label: d.name,
            })
        ))
    }

    async function getTeacher(value) {
        let teacherRes = await fetch(window.api + "getTeacher/" + value)
        teacherRes = await teacherRes.json()
        setTeacherList(teacherRes.teacher)
    }

    async function getdata(value) {
        subjectList.length = 0;
        setDepartment(value)
        let dataRes = await fetch(window.api + "getData/" + value)
        dataRes = await dataRes.json()

        if (userType === 'D') {

            dataRes.subject.map(s => (
                subjectList.push({
                    value: s.id,
                    label: s.name + "(" + s.course_code + ")",
                })
            ))
            // setSubjectList(dataRes.subject)
        } else {

            setTeacherList(dataRes.teacher)
            // setSubjectList(dataRes.subject)
            dataRes.subject.map(s => (
                subjectList.push({
                    value: s.id,
                    label: s.name + "(" + s.course_code + ")",
                })
            ))
        }
    }

    async function getStudents() {
        setViewButtonText("Getting Record...")
        const formData = new FormData()
        formData.append('faculty', faculty)
        formData.append('department', department)
        formData.append('teacher', teacher)
        formData.append('subject', subject)
        formData.append('subType', subType)
        formData.append('section', section)
        formData.append('time', time)
        formData.append('session', session)

        let result = await fetch(window.api + "getAssignedStudent", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result) {
            setViewButtonText("View List")
            setAssignList(result.student)
        }
    }

    async function addAssign() {
        // alert(agNo)
        setButtonText("Saving...")
        const formData = new FormData()
        formData.append('user_id', userId)
        formData.append('faculty', faculty)
        formData.append('department', department)
        formData.append('teacher', teacher)
        formData.append('subject', subject)
        formData.append('semester', semester)
        formData.append('r_department', rDepartment)
        formData.append('narration', JSON.stringify(narration))
        formData.append('session', session)
        formData.append('subType', subType)
        formData.append('section', section)
        formData.append('time', time)
        if (uploadType == 2) {
            formData.append('agNo', agNo)
        } else {
            formData.append('uploaded_file', file)
        }

        let result = await fetch((uploadType == 2) ? window.api + "addAssign" : window.api + "uploadAssignCsv", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result.already) {
            setButtonText("Save")
            setSuccess(false)
            setError(true)
        } else if (result.success) {
            setError(false)
            setButtonText("Save")
            setSuccessText(result.success)
            setSuccess(true)
            setAgNo("")
            setId("")
            getStudents()
        }
    }

    async function updateAssign(id, ag) {
        setId(id)
        setAgNo(ag)
        setFile(1)
        setButtonText("Update")
    }

    async function delAssign(id) {
        let delRes = await fetch(window.api + "delAssign/" + id)
        delRes = await delRes.json()
        if (delRes.success) {
            getStudents()
        }
    }

    const handleFile = async (file) => {
        setFile(file[0])
    }

    function handleNarration(data) {
        setNarration(data)
    }



    return (
        <div>
            <div class="wrapper">
                <Navbar />
                <Sidebar />
                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1 class="m-0">Course Assigned</h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Course Assigned</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="content">
                        <div class="container-fluid">
                            <div className="row">
                                <div className="col-sm-10">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <h3 class="card-title">Assign Course List for {sessionName}</h3>
                                        </div>
                                        <form>
                                            <div class="card-body">
                                                {success
                                                    ? <div className="alert alert-success" role="alert"> {successText}</div>
                                                    : <div></div>}
                                                {error
                                                    ? <div className="alert alert-danger" role="alert">  Course Already assigned to following student.</div>
                                                    : <div></div>}
                                                <div className="row">
                                                    <div className="col-sm-4 p-1">
                                                        <select value={faculty} onChange={(e) => getDepartment(e.target.value)} class="form-control">
                                                            <option value=''>--Select Faculty--</option>
                                                            {Object.keys(facultyList).length > 0 ? facultyList.map(f => (
                                                                <option value={f.id}>{f.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-4 p-1">
                                                        <select value={department} onChange={(e) => getdata(e.target.value)} class="form-control">
                                                            <option value=''>--Select Department--</option>
                                                            {Object.keys(departmentList).length > 0 ? departmentList.map(d => (
                                                                <option value={d.id}>{d.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                    </div>
                                                    {(userType === 'T') ?
                                                        <></> :
                                                        <>
                                                            <div className="col-sm-4 p-1">
                                                                <select value={teacher} onChange={(e) => setTeacher(e.target.value)} class="form-control">
                                                                    <option value=''>--Select Teacher--</option>
                                                                    {Object.keys(teacherList).length > 0 ? teacherList.map(t => (
                                                                        <option value={t.id}>{t.name}</option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>
                                                        </>}

                                                    <div className="col-sm-4 p-1">
                                                        <Select options={subjectList} onChange={(choice) => setSubject(choice.value)} class="form-control" placeholder="Select Course" />
                                                    </div>
                                                    <div className="col-sm-4 p-1">
                                                        <select className="form-control" value={subType} onChange={(e) => setSubType(e.target.value)}>
                                                            <option value='1'>Theory</option>
                                                            <option value='2'>Paractical</option>
                                                            <option value='3'>Theory /Paractical</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-4 p-1">
                                                        <select className="form-control" value={section} onChange={(e) => setSection(e.target.value)}>
                                                            <option value='A'>Section A</option>
                                                            <option value='B'>Section B</option>
                                                            <option value='C'>Section C</option>
                                                            <option value='D'>Section D</option>
                                                            <option value='E'>Section E</option>
                                                            <option value='F'>Section F</option>
                                                            <option value='G'>Section G</option>
                                                            <option value='H'>Section H</option>
                                                            <option value='I'>Section I</option>
                                                            <option value='J'>Section J</option>
                                                            <option value='K'>Section K</option>
                                                            <option value='L'>Section L</option>
                                                            <option value='M'>Section M</option>
                                                            <option value='N'>Section N</option>
                                                            <option value='O'>Section O</option>
                                                            <option value='P'>Section P</option>
                                                            <option value='Q'>Section Q</option>
                                                            <option value='R'>Section R</option>
                                                            <option value='S'>Section S</option>
                                                            <option value='T'>Section T</option>
                                                            <option value='M1'>Section M1</option>
                                                            <option value='M2'>Section M2</option>
                                                            <option value='M3'>Section M3</option>
                                                            <option value='M4'>Section M4</option>
                                                            <option value='M5'>Section M5</option>
                                                            <option value='E1'>Section E1</option>
                                                            <option value='E2'>Section E2</option>
                                                            <option value='E3'>Section E3</option>
                                                            <option value='E4'>Section E4</option>
                                                            <option value='E5'>Section E5</option>
                                                            <option value='A1'>Section A1</option>
                                                            <option value='A2'>Section A2</option>
                                                            <option value='A3'>Section A3</option>
                                                            <option value='A4'>Section A4</option>
                                                            <option value='A5'>Section A5</option>
                                                            <option value='B1'>Section B1</option>
                                                            <option value='B2'>Section B2</option>
                                                            <option value='B3'>Section B3</option>
                                                            <option value='B4'>Section B4</option>
                                                            <option value='B5'>Section B5</option>
                                                            <option value='H1'>Section H1</option>
                                                            <option value='H2'>Section H2</option>
                                                            <option value='H3'>Section H3</option>
                                                            <option value='H4'>Section H4</option>
                                                            <option value='H5'>Section H5</option>
                                                            <option value='H1H2H3'>Section H1H2H3</option>
                                                            <option value='C,L D,H4 J'>Section C,L D,H4 J</option>
                                                            <option value='N,Q EF K'>Section N,Q EF K</option>
                                                            <option value='A,B I,M G'>Section A,B I,M G</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-4 p-1">
                                                        <select className="form-control" value={time} onChange={(e) => setTime(e.target.value)}>
                                                            <option value='M'>Morning</option>
                                                            <option value='E'>Evening</option>
                                                            <option value='W'>Weekend</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 p-1">
                                                        <Select
                                                            isMulti
                                                            options={allDepartmentList}
                                                            value={narration}
                                                            onChange={handleNarration}
                                                            class="form-control"
                                                            placeholder="Select If Students from another Department"
                                                        />
                                                    </div>
                                                    <div className="col-sm-2 p-1">
                                                        <select className="form-control" value={uploadType} onChange={(e) => setUploadType(e.target.value)}>
                                                            <option value="1">Upload File</option>
                                                            <option value="2">Ag. Num</option>
                                                        </select>
                                                    </div>
                                                    {(uploadType == 2) ?
                                                        <>
                                                            <div className="col-sm-3 p-1">
                                                                <input type="text" value={agNo} onChange={(e) => setAgNo(e.target.value)} className="form-control" placeholder="2099-Ag-99999" />
                                                            </div>
                                                        </> :
                                                        <>
                                                            <div className="col-sm-6 p-1">
                                                                <input type="file" className="form-control" onChange={(e) => handleFile(e.target.files)} />
                                                                <span className="file-extension">Import File Extension (.csv Comma Delimited)<a href={formatFile} className="p-1" download>File Format</a></span>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <button
                                                    type="button"
                                                    disabled={!faculty || !department || !teacher || !subject || !subType || !section || !time
                                                        || !session || (uploadType == 1 && !file) || (uploadType == 2 && !agNo) || (userType !== 'A' && sessionStatus == 1) || userType == 'H'}
                                                    onClick={addAssign} class="btn btn-primary" style={{ flaot: 'right' }}>{buttonText}</button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success ml-1"
                                                    onClick={() => getStudents()}
                                                    disabled={!faculty || !department || !teacher || !subject
                                                        || !subType || !section || !time || !session}
                                                >{viewButtonText}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-sm-2 p-0">
                                    {Object.keys(assignList).length > 0 ?
                                        <div class="card card-success">
                                            <div class="card-header">
                                                <h3 class="card-title">All Assigned</h3>
                                            </div>

                                            <div class="card-body">
                                                <table class="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            {/* <th style={{ width: "10px" }}>#</th> */}
                                                            <th>Registration#</th>
                                                            {/* 
                                                            <th>Delete</th>
                                                            <th>Delete</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {assignList.map((a, i) => (
                                                            <tr>
                                                                {/* <td>{a.id}</td> */}

                                                                <td>{a.agNo}</td>
                                                                {/* <td>
                                                                    <i onClick={() => updateAssign(a.id, a.agNo)} class="fas fa-user-edit" title='Edit Student'></i>
                                                                </td>
                                                                <td>
                                                                    <i onClick={() => delAssign(a.id)} class="fas fa-trash-alt" title='Delete Student'></i>
                                                                </td> */}
                                                            </tr>
                                                        ))}

                                                        {/* <tr>
                                                            {assignList.map(a => (
                                                                <>
                                                                    <td width="50%">{a.agNo}</td>
                                                                </>
                                                            ))}
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> :
                                        <div className="text-center">
                                            <p className="text-danger">No Data</p>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Assign
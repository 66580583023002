import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import InputMask from 'react-input-mask'
import formatFile from './files/format.csv'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { PDFExport } from "@progress/kendo-react-pdf";
import Admin from "./enrollcomp/Admin"
import Teacher from "./enrollcomp/Teacher"
import Department from "./enrollcomp/Department"
import Faculty from "./enrollcomp/Faculty"
import Hec from "./enrollcomp/Hec"

const EnrollReport = () => {
    const userType = window.sessionStorage.getItem('type');
    return (
        <>
            <div className="wrapper">
                <Navbar />
                <Sidebar />
                <div className="content-wrapper">
                    {(userType === 'A') ?
                        <>
                            <Admin />
                        </> :
                        (userType === "T") ?
                            <>
                                <Teacher />
                            </> :
                            (userType === 'D') ?
                                <>
                                    <Department />
                                </> :
                                (userType === 'F') ?
                                    <>
                                        <Faculty />
                                    </> :
                                    (userType === 'H') ?
                                    <>
                                        <Hec />
                                    </> :
                                    <></>}
                </div>
                <Footer />
            </div>
        </>
    )
}

export default EnrollReport

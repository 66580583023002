import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
    const navigate = useNavigate()
    const userPassword = window.sessionStorage.getItem('password')
    const userType = window.sessionStorage.getItem('type')
    const userId = window.sessionStorage.getItem('id')
    const userFaculty = window.sessionStorage.getItem('userFaculty')
    const userName = window.sessionStorage.getItem('name')

    const [facultyName, setFacultyName] = useState("")
    const [departmentName, setDepartmentName] = useState("")

    async function getMisc() {
        if (userType === 'D') {
            let miscRes = await fetch(window.api + "getMiscFaculty/" + userFaculty + "/" + userId)
            miscRes = await miscRes.json()
            setFacultyName(miscRes.faculty.name)
            setDepartmentName(miscRes.department.name)
        } else {
        }
    }

    useEffect(async () => {
        window.scrollTo(0, 0);
        if (!window.sessionStorage.getItem("id")) {
            navigate("/Login")
        }
        getMisc()
    }, [])

    return (
        <>
            <nav class="main-header navbar navbar-expand navbar-white navbar-light">
                <ul class="navbar-nav ml-auto">

                </ul>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
                    </li>
                    <li class="nav-item">
                        {(userType === 'D') ?
                            <>
                                <span className="small-heading">You are loggedin as </span>  <span className="main-heading text-secondary"><b><i className="fas fa-users"></i> {facultyName}</b> ({departmentName})</span>
                            </> :
                            (userType === 'F') ?
                            <>
                                <span className="small-heading">You are loggedin as </span>  <span className="main-heading text-secondary"><b><i className="fas fa-users"></i> {userName}</b></span>
                            </> :
                            (userType === 'T') ?
                                <>
                                    <span className="small-heading">You are loggedin as </span>  <span className="main-heading text-secondary"><b><i className="fas fa-user"></i> {userName}</b></span>
                                </> :
                                (userType === 'H') ?
                                    <>
                                        <span className="small-heading ">You are loggedin as </span> <span className="main-heading ">HEC Admin</span>
                                    </> :
                                    <>
                                        <span className="small-heading ">You are loggedin as </span> <span className="main-heading ">QEC Admin</span>
                                    </>}
                    </li>
                </ul>
                {/* <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i class="fas fa-expand-arrows-alt"></i>
                        </a>
                    </li>
                </ul> */}
            </nav>
        </>
    )
}

export default Navbar
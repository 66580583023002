import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import InputMask from 'react-input-mask'
import formatFile from './files/format.csv'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { PDFExport } from "@progress/kendo-react-pdf";


const AssignReport = () => {
    const userPassword = window.sessionStorage.getItem('password')
    const userType = window.sessionStorage.getItem('type')
    const userId = window.sessionStorage.getItem('id')
    const userFaculty = window.sessionStorage.getItem('userFaculty')
    const userDepartment = window.sessionStorage.getItem('userDepartment')
    const facName = window.sessionStorage.getItem('facName')
    const deptName = window.sessionStorage.getItem('deptName')
    const techDesg = window.sessionStorage.getItem('techDesg')
    const techName = window.sessionStorage.getItem('name')

    const [faculty, setFaculty] = useState("")
    const [department, setDepartment] = useState("")
    const [reportType, setReportType] = useState("")
    const [selectedSession, setSelectedSession] = useState("")
    const [selectedTeacher, setSelectedTeacher] = useState("")
    const [teacherData, setTeacherData] = useState("")
    const [selectedYear, setSelectedYear] = useState("")
    const [sessionName, setSessionName] = useState("")
    const [session, setSession] = useState("")
    const [sessionStatus, setSessionStatus] = useState("")
    const [facultyList, setFacultyList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [teacherList, setTeacherList] = useState([])
    const [assignList, setAssignList] = useState([])
    const [teacherAssignList, setTeacherAssignList] = useState([])
    const [enrolledstudentList, setEnrolledStudentList] = useState([])
    const [sessionList, setSessionList] = useState([])
    const [isChecked, setIsChecked] = useState([])
    const [totalEnrolled, setTotalEnrolled] = useState("")

    const [enrFaculty, setEnrFaculty] = useState("")
    const [enrDepartment, setEnrDepartment] = useState("")
    const [enrSession, setEnrSession] = useState("")
    const [enrTeacher, setEnrTeacher] = useState("")
    const [enrSubject, setEnrSubject] = useState("")

    const [loader, setLoader] = useState(false)
    const [getStudentLoader, setGetStudentLoader] = useState(false)
    const [delLoader, setDelLoader] = useState(false)
    const [show, setShow] = useState(false);

    const [editStatus, setEditStatus] = useState(false);

    const [evalPercentage, setEvalPercentage] = useState("");

    const [sessionNames, setSessionNames] = useState([])

    const closeStudents = () => {
        setShow(false); setEnrFaculty(""); setEnrDepartment(); setEnrSession(); setEnrTeacher(); setEnrSubject();
        if (editStatus) {
            if (userType === 'A') {
                getAssignReport(department)
            } else if (userType === 'D') {
                getDepartmentAssignReport(userFaculty, userId, session)
            } else if (userType === 'T') {
                getTeacherAssignReport(userFaculty, userDepartment, userId, session)
            }
        }
    }
    const showStudents = () => setShow(true);


    useEffect(async () => {
        window.scrollTo(0, 0);
        getAssignData()
    }, [])

    async function getAssignData() {
        let assignDataRes = await fetch(window.api + "getAssignData")
        assignDataRes = await assignDataRes.json()
        setSession(assignDataRes.session.id)
        setSessionName(assignDataRes.session.name)
        setSessionStatus(assignDataRes.session.Locked)
        setFacultyList(assignDataRes.faculty)
        setSessionList(assignDataRes.session_list)
        if (userType === 'D') {
            setFaculty(userFaculty)
            getDepartment(userFaculty)
            setDepartment(userId)
        } else if (userType === 'F') {
            setFaculty(userId)
            getDepartment(userId)
        }
    }

    async function getAssignReport() {

        setLoader(true)
        const formData = new FormData()

        formData.append('faculty', faculty)
        formData.append('department', department)
        formData.append('session', selectedSession)
        formData.append('year', selectedYear)
        formData.append('teacher', selectedTeacher)

        let result = await fetch(window.api + "getTeacherAssignReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        setLoader(false)
        if (result) {
            // setAssignList(result.assigned_teacher)
            // setTotalEnrolled(result.count_subjects)
            // setEditStatus(false)
            setTeacherAssignList(result.assigned_teacher)
            setEditStatus(false)
            setEvalPercentage(result.eval_percentage)
            // setDepRepStatus(result.session_status.report_status)
            // setTechRepStatus(result.session_status.tech_rpt_status)
            // setTechCerStatus(result.session_status.tech_cer_status)
            setTechCerStatus(result.cer_status)
            setSessionNames(result.session_names)
            setTeacherData(result.teacher_data)
        }
    }

    async function getDepartmentAssignReport(fac, dept, sess) {

        setLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('session', sess)

        let result = await fetch(window.api + "getAssignReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        setLoader(false)
        if (result) {
            setAssignList(result.assigned_teacher)
            setEditStatus(false)
        }
    }

    const [depRepStatus, setDepRepStatus] = useState("")
    const [techRepStatus, setTechRepStatus] = useState("")
    const [techCerStatus, setTechCerStatus] = useState("")

    async function getTeacherAssignReport(fac, dept, tech, sess, ye) {

        setLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('session', sess)
        formData.append('year', ye)
        formData.append('teacher', tech)

        let result = await fetch(window.api + "getTeacherAssignReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        console.warn(result)

        setLoader(false)
        if (result) {
            setTeacherAssignList(result.assigned_teacher)
            setEditStatus(false)
            setEvalPercentage(result.eval_percentage)
            // setDepRepStatus(result.session_status.report_status)
            // setTechRepStatus(result.session_status.tech_rpt_status)
            // setTechCerStatus(result.session_status.tech_cer_status)
            setTechCerStatus(result.cer_status)
            setSessionNames(result.session_names)
            setTeacherData(result.teacher_data)
        }
    }

    async function getDepartment(value) {
        setFaculty(value)
        let departmentRes = await fetch(window.api + "getDepartment/" + value)
        departmentRes = await departmentRes.json()
        setDepartmentList(departmentRes.department)
    }

    async function getTeacher(value) {
        setDepartment(value)
        let teacherRes = await fetch(window.api + "getTeacher/" + value)
        teacherRes = await teacherRes.json()
        setTeacherList(teacherRes.teacher)
    }

    //Delete enrollments 

    async function getStudent(fac, dept, sess, tech, subj, sect, time) {

        setEnrFaculty(fac); setEnrDepartment(dept); setEnrSession(sess); setEnrTeacher(tech); setEnrSubject(subj);
        setShow(true)
        setGetStudentLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('session', sess)
        formData.append('teacher', tech)
        formData.append('subject', subj)
        formData.append('section', sect)
        formData.append('time', time)

        let result = await fetch(window.api + "getEnrolledStudents", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        setGetStudentLoader(false)
        if (result) {
            setEnrolledStudentList(result.enrolled_students)
        }
    }
    const handlecheckbox = (e) => {
        const { value, checked } = e.target
        if (checked) {
            setIsChecked([...isChecked, value])
        } else {
            setIsChecked(isChecked.filter((e) => e !== value))
        }
    }

    const allDelete = async () => {
        setDelLoader(true)
        const formData = new FormData()
        formData.append('assignList', JSON.stringify(isChecked))

        let result = await fetch(window.api + "deleteAssign", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result.success) {
            setEditStatus(true)
            setDelLoader(false)
            getStudent(enrFaculty, enrDepartment, enrSession, enrTeacher, enrSubject)
        }
    }

    const deleteAllEnrollment = async (faculty, department, session, teacher, subject, section, time) => {

        const formData = new FormData()
        formData.append('faculty', faculty)
        formData.append('department', department)
        formData.append('session', session)
        formData.append('teacher', teacher)
        formData.append('subject', subject)
        formData.append('section', section)
        formData.append('time', time)

        let result = await fetch(window.api + "deleteAllEnrollment", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result.success) {

            if (userType === 'A') {
                getAssignReport(department)
            } else if (userType === 'D') {
                getDepartmentAssignReport(userFaculty, userId, session)
            } else if (userType === 'T') {
                getTeacherAssignReport(userFaculty, userDepartment, userId, session)
            }
        }
    }

    //Enable Disable Functions

    const [agNo, setAgNo] = useState("")
    const [assignId, setAssignId] = useState("")
    const [reason, setReason] = useState("")
    const [stopButtonText, setStopButtonText] = useState("Save")

    const [reasonModal, setReasonModal] = useState(false)
    const [reasonLoader, setReasonLoader] = useState(false)
    const [enableLoader, setEnaleLoader] = useState(false)

    const closeReasonModal = () => {
        setAssignId("")
        setAgNo("")
        setReason("")
        setReasonModal(false)
        getStudent(enrFaculty, enrDepartment, enrSession, enrTeacher, enrSubject)
    }
    const showReasonModal = (assignId, agNo) => {
        setAssignId(assignId)
        setAgNo(agNo)
        setReasonModal(true)
    }
    const stopAssign = async () => {
        setReasonLoader(true)
        setStopButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', assignId)
        formData.append('reason', reason)

        let result = await fetch(window.api + "stopAssign", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result.success) {
            setReasonLoader(false)
            setStopButtonText("Save")
            closeReasonModal()
        }
    }

    const enableAssign = async (assignId) => {
        setAssignId(assignId)
        setEnaleLoader(true)
        const formData = new FormData()
        formData.append('id', assignId)

        let result = await fetch(window.api + "enableAssign", {
            method: 'POST',
            body: formData
        })

        result = await result.json()
        if (result.success) {
            setEnaleLoader(false)
            setAssignId("")
            getStudent(enrFaculty, enrDepartment, enrSession, enrTeacher, enrSubject)
        }
    }


    // Get Percentage Report
    const [subjectName, setSubjectName] = useState("")
    const [subjectSection, setSubjectSection] = useState("")
    const [subjectTime, setSubjectTime] = useState("")
    const [subjectType, setSubjectType] = useState("")

    const [perReportData, setPerReportData] = useState([])
    const [perReportAvg, setPerReportAvg] = useState("")
    const [perCommentsData, setPerCommentsData] = useState([])
    const [perTotalScore, setPerTotalScore] = useState("")

    const [perReportModal, setPerReportModal] = useState(false)
    const [perReportLoader, setPerReportLoader] = useState(false)

    const [sessName, setSessName] = useState("")

    const closePerReportModal = () => {
        setPerReportData([])
        setPerReportModal(false)
    }

    async function getPercentageReport(fac, dept, sess, tech, subj, sect, time, subType, subject_code, subject_name, sess_name) {
        setSessName(sess_name)
        setSubjectName(subject_name + "(" + subject_code + ")")
        setSubjectSection(sect); setSubjectTime(time); setSubjectType(subType);

        setPerReportModal(true)
        setPerReportLoader(true)
        const formData = new FormData()
        formData.append('faculty', fac)
        formData.append('department', dept)
        formData.append('sess_id', sess)
        formData.append('teach_id', tech)
        formData.append('sub_id', subj)
        formData.append('sec_id', sect)
        formData.append('time_id', time)
        formData.append('sub_type', subType)

        let result = await fetch(window.api + "getPercentageReport", {
            method: 'POST',
            body: formData
        });
        result = await result.json()

        if (result.all_report) {
            setPerReportData(result.all_report)
            setPerReportAvg(result.avg_score)
            setPerCommentsData(result.comments)
            setPerTotalScore(result.total_percentage)
            setPerReportLoader(false)
        }
    }

    const pdfExportComponent = React.useRef(null);
    // Get Certificate
    const [certificateStatus, setCertificateStatus] = useState(false)

    const pdfCertificate = React.useRef(null);
    const [certificateModal, setCertificateModal] = useState(false)

    const closeCertficateModal = () => {
        setCertificateModal(false)
    }

    const getCertificate = () => {
        setCertificateModal(true)
    }

    const changeSelectedSession = (value) => {
        setSelectedSession(value)
        setSelectedYear("")
    }

    const changeSelectedYear = (value) => {
        setSelectedYear(value)
        setSelectedSession("")
    }

    return (
        <div>
            <div className="wrapper">
                <Navbar />
                <Sidebar />
                <div className="content-wrapper">
                    <Modal show={show} onHide={closeStudents}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enrolled Students</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {(isChecked.length > 0) ?
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={allDelete}
                                                    disabled={userType == 'H'}
                                                >
                                                    {delLoader ? "Deleting.." : "Delete"}
                                                </button>
                                            </> :
                                            <></>}
                                    </div>
                                    {getStudentLoader ?
                                        <>
                                            <i className="text-center">Fetching Record Please Wait...</i>
                                        </> :
                                        <>
                                            {Object.keys(enrolledstudentList).length > 0 ?
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Reg #</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {enrolledstudentList.map((s, index) => (
                                                            <tr key={index}>
                                                                <td className="text-center">
                                                                    {(userType === 'A' || sessionStatus == 0) ?
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={s.id}
                                                                                checked={s.isChecked}
                                                                                onChange={(e) => handlecheckbox(e)}
                                                                            />
                                                                        </> :
                                                                        <></>
                                                                    }
                                                                </td>
                                                                <td>{s.agNo}</td>
                                                                <td>
                                                                    {(s.feedback_status == 1) ?
                                                                        <>
                                                                            <i className="text-success">Feedback Submitted</i>
                                                                        </> :
                                                                        <>
                                                                            {(userType === 'A' || sessionStatus == 0) ?
                                                                                <>

                                                                                    {(s.status == 1) ?
                                                                                        <>
                                                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => showReasonModal(s.id, s.agNo)} disabled={userType == 'H'}>Stop</button>
                                                                                        </> :
                                                                                        <>
                                                                                            <button type="button" className="btn btn-success btn-sm" onClick={() => enableAssign(s.id)} disabled={userType == 'H'}>
                                                                                                {(assignId == s.id && enableLoader) ? 'Enabling..' : 'Enable'}
                                                                                            </button>

                                                                                        </>}
                                                                                </> :
                                                                                <><i className="text-secondary">No Action</i></>}
                                                                        </>}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table> :
                                                <div className="text-center"><i className="text-danger">No Data</i></div>}
                                        </>}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={reasonModal} onHide={closeReasonModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{agNo}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as="textarea" rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={stopAssign} disabled={!reason || !assignId}>
                                {reasonLoader ? stopButtonText : stopButtonText}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={perReportModal} size="xl" onHide={closePerReportModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>View Report</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    {(perReportLoader) ?
                                        <><p className="text-info">Fetching Record Please Wait..</p></> :
                                        <>
                                            {Object.keys(perReportData).length > 0 ?
                                                <>
                                                    <button
                                                        className="btn btn-info btn-sm"
                                                        onClick={() => {
                                                            if (pdfExportComponent.current) {
                                                                pdfExportComponent.current.save();
                                                            }
                                                        }}
                                                    >
                                                        Export PDF
                                                    </button>
                                                    <PDFExport paperSize="A4" margin="0.5cm" ref={pdfExportComponent}>
                                                        <p>
                                                            <b>Course: </b> {subjectName}<br />
                                                            <b>Session: </b> {sessName}
                                                            {/* Section {subjectSection}, {(subjectTime === 'M') ? "Morning" : (subjectTime === 'E') ? "Evening" : (subjectTime === 'W') ? "Weekend" : ""}, {(subjectType === '1') ? "Theory" : (subjectType === '2') ? "Paractical" : (subjectType === '3') ? "Theory /Paractical" : ""} */}
                                                        </p>
                                                        <table className="table table-sm">
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th>Question</th>
                                                                    <th>Percentage</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {perReportData.map((r, index) => (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td>{r.question}</td>
                                                                            <td className="text-center">{r.Percentage_Vlaue} %</td>
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                                <tr>
                                                                    <td  className="text-center"><b>Average Score</b></td>
                                                                    <td className="text-center"><b>{perReportAvg}%</b></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <h2 className="text-center">Comments</h2>
                                                        {perCommentsData.map((c, index) => (
                                                            <>
                                                                <tr key={index}>
                                                                    <td>{c.Teacher_Comments}</td>
                                                                </tr>
                                                            </>
                                                        ))}

                                                    </PDFExport>
                                                </> :
                                                <>
                                                    <p className="text-danger">No Record Found</p>
                                                </>}
                                        </>}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={closePerReportModal} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={certificateModal} size="lg" onHide={closeCertficateModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <button
                                    className="btn btn-info btn-sm"
                                    onClick={() => {
                                        if (pdfCertificate.current) {
                                            pdfCertificate.current.save();
                                        }
                                    }}
                                >
                                    Download PDF
                                </button>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <PDFExport paperSize="A4" margin="0.5cm" landscape="true" ref={pdfCertificate}>
                                        <div className='container border'>
                                            <div className="row mt-2">
                                                <div className="col-md-2 text-end">
                                                    <img src="images/result_logo.jpeg" width="90%" height="110px" />
                                                </div>
                                                <div className="col-md-10 text-center">
                                                    <span style={{ fontSize: "30px" }}>Quality Enhancement Cell</span><br />
                                                    <span style={{ fontSize: "30px" }}><b>University of Agriculture, Faisalabad</b></span><br />
                                                    <span>Phone:  92 (41) 9200161-170/ Ext. 3305; Email: directorqec@uaf.edu.pk</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-9">

                                                </div>
                                                <div className="col-md-3">
                                                    Dated: <u>{new Date().toLocaleString("en-US", { day: '2-digit' })}.{new Date().toLocaleString("en-US", { month: "2-digit" })}.{new Date().getFullYear()}</u>
                                                </div>
                                            </div><br />
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h6>TO WHOM IT MAY CONCERN</h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="text-justify">It is certified that student evaluation report of {teacherData.name}, {(teacherData.designation == 1) ?
                                                        "Professor" :
                                                        (teacherData.designation == 2) ?
                                                            "Associate Professor" :
                                                            (teacherData.designation == 3) ?
                                                                "Assistant Professor" :
                                                                (teacherData.designation == 4) ?
                                                                    "Lecturer" :
                                                                    (teacherData.designation == 5) ?
                                                                        "Visiting" :
                                                                        (teacherData.designation == 6) ?
                                                                            "Subject Expert" :
                                                                            (teacherData.designation == 7) ?
                                                                                "Research Officer" :
                                                                                ""
                                                    }, {teacherData.dept_name} is {evalPercentage} % for the {(selectedYear != '') ? <> year {selectedYear}</> : 'smester'} ({sessionNames.map((s, index) => (
                                                        (index != 0) ?
                                                            <>
                                                                + {s}
                                                            </> :
                                                            <>
                                                                {s}
                                                            </>
                                                    ))}).</p>
                                                </div>
                                            </div>
                                            <br /><br />
                                            <span style={{ color: "red" }}>Note!</span>
                                            <ul>
                                                <li>This is computer generated report and needs no Signatures.</li>
                                            </ul>
                                        </div>
                                    </PDFExport>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Assigned Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Assigned Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-primary">
                                        <div className="card-header text-center">
                                            <h3 className="card-title">Course Enrollments</h3>
                                        </div>
                                        <form>
                                            <div className="card-body">
                                                <div className="row">
                                                    {(userType === 'A' || userType === 'H' || userType === 'F') ?
                                                        <>
                                                            <div className="col-sm-3 p-1">
                                                                <select
                                                                    value={faculty}
                                                                    onChange={(e) => getDepartment(e.target.value)}
                                                                    className="form-control form-control-sm"
                                                                    disabled={userType === 'F' ? 'disabled' : ''}
                                                                >
                                                                    <option value=''>--Select Faculty--</option>
                                                                    {Object.keys(facultyList).length > 0 ? facultyList.map((f, index) => (
                                                                        <option key={index} value={f.id}>{f.name}</option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>
                                                            <div className="col-sm-3 p-1">
                                                                <select value={department} onChange={(e) => getTeacher(e.target.value)} className="form-control form-control-sm">
                                                                    <option value=''>--Select Department--</option>
                                                                    {Object.keys(departmentList).length > 0 ? departmentList.map((d, index) => (
                                                                        <option key={index} value={d.id}>{d.name}</option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>
                                                            <div className="col-sm-3 p-1">
                                                                <select value={selectedTeacher} onChange={(e) => setSelectedTeacher(e.target.value)} className="form-control form-control-sm">
                                                                    <option value=''>--Select Teacher--</option>
                                                                    {Object.keys(teacherList).length > 0 ? teacherList.map((t, index) => (
                                                                        <option key={index} value={t.id}>{t.name}</option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>
                                                            {/* <div className="col-sm-2 p-1">
                                                                <select value={selectedSession} onChange={(e) => setSelectedSession(e.target.value)} className="form-control form-control-sm">
                                                                    <option>--Select Session--</option>
                                                                    {Object.keys(sessionList).length > 0 ? sessionList.map((s, index) => (
                                                                        <option key={index} value={s.id}>{s.name}</option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div> */}
                                                            <div className="col-sm-2 p-1">
                                                                <select value={reportType} onChange={(e) => setReportType(e.target.value)} className="form-control form-control-sm">
                                                                    <option value="">--Report Type--</option>
                                                                    <option value="S">Session Wise</option>
                                                                    <option value="Y">Year Wise</option>
                                                                </select>
                                                            </div>
                                                            {(reportType === "S") ?
                                                                <>
                                                                    <div className="col-sm-2 p-1">
                                                                        <select value={selectedSession} onChange={(e) => changeSelectedSession(e.target.value)} className="form-control form-control-sm">
                                                                            <option value="">--Select Session--</option>
                                                                            {Object.keys(sessionList).length > 0 ? sessionList.map((s, index) => (
                                                                                <option key={index} value={s.id}>{s.name}</option>
                                                                            )) : <></>}
                                                                        </select>
                                                                    </div>
                                                                </> :
                                                                (reportType === "Y") ?
                                                                    <>
                                                                        <div className="col-sm-2 p-1">
                                                                            <select value={selectedYear} onChange={(e) => changeSelectedYear(e.target.value)} className="form-control form-control-sm">
                                                                                <option value="">--Select Year--</option>
                                                                                <option value="2023">2023</option>
                                                                                <option value="2022">2022</option>
                                                                            </select>
                                                                        </div>
                                                                    </> :
                                                                    <></>}
                                                            <div className="col-sm-1 p-1">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={getAssignReport}
                                                                    disabled={!department || !faculty || !selectedTeacher || (!selectedSession && !selectedYear) || loader}>Get</button>
                                                            </div>
                                                        </> :
                                                        (userType === 'D') ?
                                                            <>
                                                                <div className="col-sm-2 p-1">
                                                                    <select value={selectedSession} onChange={(e) => setSelectedSession(e.target.value)} className="form-control form-control-sm">
                                                                        <option>--Select Session--</option>
                                                                        {Object.keys(sessionList).length > 0 ? sessionList.map((s, index) => (
                                                                            <option key={index} value={s.id}>{s.name}</option>
                                                                        )) : <></>}
                                                                    </select>
                                                                </div>
                                                                <div className="col-sm-2 p-1">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success btn-sm"
                                                                        onClick={() => getDepartmentAssignReport(userFaculty, userId, selectedSession)}
                                                                        disabled={!selectedSession || loader}>Get Report</button>
                                                                </div>
                                                            </> :
                                                            (userType === 'T') ?
                                                                <>
                                                                    <div className="col-sm-2 p-1">
                                                                        <select value={reportType} onChange={(e) => setReportType(e.target.value)} className="form-control form-control-sm">
                                                                            <option value="">--Report Type--</option>
                                                                            <option value="S">Session Wise</option>
                                                                            <option value="Y">Year Wise</option>
                                                                        </select>
                                                                    </div>
                                                                    {(reportType === "S") ?
                                                                        <>
                                                                            <div className="col-sm-2 p-1">
                                                                                <select value={selectedSession} onChange={(e) => changeSelectedSession(e.target.value)} className="form-control form-control-sm">
                                                                                    <option value="">--Select Session--</option>
                                                                                    {Object.keys(sessionList).length > 0 ? sessionList.map((s, index) => (
                                                                                        <option key={index} value={s.id}>{s.name}</option>
                                                                                    )) : <></>}
                                                                                </select>
                                                                            </div>
                                                                        </> :
                                                                        (reportType === "Y") ?
                                                                            <>
                                                                                <div className="col-sm-2 p-1">
                                                                                    <select value={selectedYear} onChange={(e) => changeSelectedYear(e.target.value)} className="form-control form-control-sm">
                                                                                        <option value="">--Select Year--</option>
                                                                                        <option value="2023">2023</option>
                                                                                        <option value="2022">2022</option>
                                                                                    </select>
                                                                                </div>
                                                                            </> :
                                                                            <></>}
                                                                    <div className="col-sm-2 p-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success btn-sm"
                                                                            onClick={() => getTeacherAssignReport(userFaculty, userDepartment, userId, selectedSession, selectedYear)}
                                                                            disabled={(!selectedSession && !selectedYear) || loader}>Get Report</button>
                                                                    </div>
                                                                </> :
                                                                <></>}
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-sm-4">
                                                        {totalEnrolled ? <span className="text-success"><b>Total Enrolled Subjects:</b> {totalEnrolled}</span> : <></>}
                                                    </div>

                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        {(loader) ?
                                                            <div className="text-center">
                                                                <i>Fetching Data Please Wait...</i>
                                                            </div> :
                                                            <>
                                                                {(userType === 'T' || userType === 'A') ?
                                                                    <>
                                                                        {Object.keys(teacherAssignList).length > 0 ?
                                                                            <>
                                                                                {(techCerStatus == 1) ?
                                                                                    <>
                                                                                        <button type="button" className="btn btn-success btn-sm" onClick={() => getCertificate()}>Certificate</button>
                                                                                    </> : <></>}
                                                                                <table className="table table-sm">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Del</th>
                                                                                            <th>Course Details</th>
                                                                                            <th>St.</th>
                                                                                            <th>Sub.</th>
                                                                                            <th>Sec.</th>
                                                                                            <th>Time</th>
                                                                                            <th>Type</th>
                                                                                            <th>Score</th>
                                                                                            <th>Report</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {teacherAssignList.map((l, index) => (
                                                                                            <tr key={index}>
                                                                                                <td>
                                                                                                    {(l.feedback_status > 0 || userType === 'D' || (userType !== 'A' && sessionStatus == 1) || userType === 'H') ?
                                                                                                        <>
                                                                                                            <i
                                                                                                                class="fas fa-trash-alt pr-2 text-secondary"
                                                                                                                title="Feedback Submitted"></i>
                                                                                                        </> :
                                                                                                        <>
                                                                                                            <i
                                                                                                                onClick={() => deleteAllEnrollment(l.faculty_id, l.department_id, l.session_id, l.teacher_id, l.subject_id, l.section, l.time)}
                                                                                                                class="fas fa-trash-alt pr-2 text-danger"
                                                                                                                title="Delete All Enrollment"></i>
                                                                                                        </>}
                                                                                                </td>
                                                                                                <td>
                                                                                                    ({l.subject_code}){l.subject_name}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <i className="text-info" onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, l.teacher_id, l.subject_id, l.section, l.time)}>{l.enrolled}</i>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {l.feedback_status}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {l.section}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {(l.time === 'M') ? "Morning" : (l.time === 'E') ? "Evening" : (l.time === 'W') ? "Weekend" : ""}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {(l.subType === '1') ? "Theory" : (l.subType === '2') ? "Paractical" : (l.subType === '3') ? "Theory /Paractical" : ""}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {(l.sess_tech_cer_status == 1) ?
                                                                                                        <>
                                                                                                            {l.current_score} %
                                                                                                        </> :
                                                                                                        <>
                                                                                                            <i className="text-warning">Pending</i>
                                                                                                        </>}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {(l.sess_tech_rpt_status == 1) ?
                                                                                                        <>
                                                                                                            <i className="text-info font-weight-bold"
                                                                                                                onClick={() => getPercentageReport(l.faculty_id, l.department_id, l.session_id, l.teacher_id, l.subject_id, l.section, l.time, l.subType, l.subject_code, l.subject_name, l.session_name)}>View</i>
                                                                                                        </> :
                                                                                                        <>
                                                                                                            <i className="text-warning">Pending</i>
                                                                                                        </>}

                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                    {(techCerStatus == 1) ?
                                                                                        <>
                                                                                            <tfoot>
                                                                                                <tr>
                                                                                                    <td className="text-center" colSpan={7}>
                                                                                                        <b>Total Score</b>
                                                                                                    </td>
                                                                                                    <td colSpan={2}>
                                                                                                        <b>{evalPercentage} %</b>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tfoot>
                                                                                        </> :
                                                                                        <></>}
                                                                                </table>
                                                                            </> :
                                                                            <div className="text-center"><i className="text-danger"></i></div>}
                                                                    </> :
                                                                    <>

                                                                        {Object.keys(assignList).length > 0 ?
                                                                            <table className="table table-sm">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Teacher Name</th>
                                                                                        <th>Course Details</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {assignList.map((a, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{a.teacher_name}</td>
                                                                                            <td>
                                                                                                {Object.keys(a.assigned_data).length > 0 ?
                                                                                                    <tr>
                                                                                                        <th>Delete</th>
                                                                                                        <th>Subject</th>
                                                                                                        <th>St. No</th>
                                                                                                        <th>Feedback</th>
                                                                                                        <th>Sec.</th>
                                                                                                        <th>Time</th>
                                                                                                        <th>Type</th>
                                                                                                        <th>Report</th>
                                                                                                    </tr>
                                                                                                    : <i className="text-danger">Enrollment Not Found</i>}

                                                                                                {a.assigned_data.map(l => (
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {(userType === 'D' || l.feedback_status > 0 || (userType !== 'A' && sessionStatus == 1 || userType === 'H')) ?
                                                                                                                <>
                                                                                                                    <i
                                                                                                                        class="fas fa-trash-alt pr-2 text-secondary"
                                                                                                                        title="Feedback Submitted"></i>
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    <i
                                                                                                                        onClick={() => deleteAllEnrollment(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time)}
                                                                                                                        class="fas fa-trash-alt pr-2 text-danger"
                                                                                                                        title="Delete All Enrollment"></i>
                                                                                                                </>}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            ({l.subject_code}){l.subject_name}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <i
                                                                                                                className="text-info"
                                                                                                                onClick={() => getStudent(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time)}>{l.enrolled}</i>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {l.feedback_status}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {l.section}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {(l.time === 'M') ? "M" : (l.time === 'E') ? "E" : (l.time === 'W') ? "W" : ""}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {(l.subType === '1') ? "T" : (l.subType === '2') ? "P" : (l.subType === '3') ? "T/P" : ""}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {(l.sess_report_status == 1) ?
                                                                                                                <>
                                                                                                                    <i className="text-info font-weight-bold"
                                                                                                                        onClick={() => getPercentageReport(l.faculty_id, l.department_id, l.session_id, a.teacher_id, l.subject_id, l.section, l.time, l.subType, l.subject_code, l.subject_name)}>View</i>
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    <i className="text-warning">Pending</i>
                                                                                                                </>}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table> :
                                                                            <div className="text-center"><i className="text-danger"></i></div>}
                                                                    </>}
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
                <Footer />
            </div >
        </div >
    )
}

export default AssignReport
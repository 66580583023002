import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"

const Post = () => {
    const userId = window.sessionStorage.getItem('id')
    const [id, setId] = useState("")
    const [type, setType] = useState("")
    const [channel, setChannel] = useState("")
    const [channelLink, setChannelLink] = useState("")
    const [title, setTitle] = useState("")
    const [detail, setDetail] = useState("")
    const [image, setImage] = useState("")
    const [buttonText, setButtonText] = useState("Save")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [postList, setPostList] = useState([])
    const [postTypeList, setPostTypeList] = useState([])

    const handleImage = (file) => {
        setImage(file[0])
    }

    useEffect(async () => {
        window.scrollTo(0, 0);
        getPost()
    }, [])

    async function getPost() {
        let postRes = await fetch(window.api + "postList")
        postRes = await postRes.json()
        setPostList(postRes.post)
        setPostTypeList(postRes.post_type)
    }

    async function addPost() {
        setButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', id)
        formData.append('user_id', userId)
        formData.append('type', type)
        formData.append('channel', channel)
        formData.append('channel_link', channelLink)
        formData.append('title', title)
        formData.append('detail', detail)
        formData.append('image', image)

        let result = await fetch(window.api + "addPost", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setButtonText("Save")
            setSuccess(false)
            setError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setError(false)
            setButtonText("Save")
            setSuccess(true)
            setType("")
            setChannel("")
            setChannelLink("")
            setTitle("")
            setDetail("")
            setImage("")
            setId("")
            getPost()
            window.scrollTo(0, 0)
        }
    }

    async function updatePost(id,type,channel,channe_link,title,detail) {
        setId(id)
        setType(type)
        setChannel(channel)
        setChannelLink(channe_link)
        setTitle(title)
        setDetail(detail)
        setButtonText("Update")
    }

    async function delPost(id) {
        let delRes = await fetch(window.api+"delPost/"+id)
        delRes = await delRes.json()
        if(delRes.success){
            getPost()
        }
    }

    return (
        <>
            <div class="wrapper">
                {/* <div class="preloader flex-column justify-content-center align-items-center">
                    <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
                </div> */}
                <Navbar />
                <Sidebar />
                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1 class="m-0">Posts</h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Posts</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section class="content">
                        <div class="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <h3 class="card-title">Add Post</h3>
                                        </div>
                                        <form>
                                            <div class="card-body">
                                                {success
                                                    ? <div className="alert alert-success" role="alert"> Post Added successfully.</div>
                                                    : <div></div>
                                                }
                                                {error
                                                    ? <div className="alert alert-danger" role="alert">  Email Already Registered.</div>
                                                    : <div></div>
                                                }
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <div class="form-group">
                                                            <label>Post Type</label>
                                                            <select value={type} onChange={(e) => setType(e.target.value)} class="form-control">
                                                                <option value=''>--Select Type--</option>
                                                                {Object.keys(postTypeList).length > 0 ? postTypeList.map(t => (
                                                                    <option value={t.id}>{t.name}</option>
                                                                )) : <></>}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div class="form-group">
                                                            <label>Channel</label>
                                                            <input type="text" value={channel} onChange={(e) => setChannel(e.target.value)} class="form-control" placeholder="Enter Channel Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div class="form-group">
                                                            <label>Channel Link</label>
                                                            <input type="text" value={channelLink} onChange={(e) => setChannelLink(e.target.value)} class="form-control" placeholder="Enter Channel Link" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div class="form-group">
                                                            <label>Image</label>
                                                            <div class="input-group">
                                                                <div class="custom-file">
                                                                    <input type="file" onChange={e => handleImage(e.target.files)} class="custom-file-input" />
                                                                    <label class="custom-file-label">Choose file</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div class="form-group">
                                                            <label>Title</label>
                                                            <input type="email" value={title} onChange={(e) => setTitle(e.target.value)} class="form-control" placeholder="Enter Title" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div class="form-group">
                                                            <label>Detail</label>
                                                            <textarea value={detail} onChange={(e) => setDetail(e.target.value)} class="form-control" placeholder="Enter Detail" rows={'5'}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <button type="button" onClick={addPost} class="btn btn-primary">{buttonText}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 class="card-title">All Posts</h3>
                                        </div>

                                        <div class="card-body p-0">
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "10px" }}>#</th>
                                                        <th>Channel</th>
                                                        <th>Channel Link</th>
                                                        <th>Title</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(postList).length > 0 ? postList.map(p => (
                                                        <tr>
                                                            <td>{p.id}</td>
                                                            <td>{p.channel}</td>
                                                            <td>{p.channe_link}</td>
                                                            <td>{p.title}</td>
                                                            <td>
                                                                <i onClick={() => updatePost(p.id, p.type, p.channel, p.channe_link, p.title, p.detail)} class="fas fa-user-edit" title='Edit user'></i>
                                                            </td>
                                                            <td>
                                                                <i onClick={() => delPost(p.id)} class="fas fa-trash-alt" title='Delete Team'></i>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                        <></>}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Post
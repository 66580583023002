import React, { useState, useEffect } from "react"

const Faculty = () => {
    const userType = window.sessionStorage.getItem('type')

    const [id, setId] = useState("")
    const [facultyList, setFacultyList] = useState([])
    const [buttonText, setButtonText] = useState("Save")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [name, setName] = useState("")

    useEffect(async () => {
        window.scrollTo(0, 0);
        getFaculty()
    }, [])

    async function getFaculty() {
        let miscRes = await fetch(window.api + "getFaculty")
        miscRes = await miscRes.json()
        setFacultyList(miscRes.faculty)

    }

    async function addFaculty() {
        setButtonText("Saving...")
        const formData = new FormData()
        formData.append('id', id)
        formData.append('name', name)

        let result = await fetch(window.api + "addFaculty", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.already) {
            setButtonText("Save")
            setSuccess(false)
            setError(true)
            window.scrollTo(0, 0)
        } else if (result.success) {
            setError(false)
            setButtonText("Save")
            setSuccess(true)
            setName('')
            setId('')
            getFaculty()
            window.scrollTo(0, 0)
        }
    }

    function updateMisc(id, name) {
        setId(id)
        setName(name)
        setButtonText("Update")
    }

    async function delFaculty(id) {
        let delRes = await fetch(window.api + "delFaculty/" + id)
        delRes = await delRes.json()
        getFaculty()
    }
    return (
        <>
            <div class="card card-primary">
                {/* <div class="card-header">
                                            <h3 class="card-title">Add User</h3>
                                        </div> */}
                <div class="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Faculty</h3>
                                </div>
                                <form>
                                    <div class="card-body">
                                        {success
                                            ? <div className="alert alert-success" role="alert"> Done successfully.</div>
                                            : <div></div>
                                        }
                                        {error
                                            ? <div className="alert alert-danger" role="alert">  Faculty Already Added.</div>
                                            : <div></div>
                                        }
                                        <div class="form-group">
                                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Faculty Name" />
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" onClick={addFaculty} class="btn btn-primary" disabled={!name || userType == 'H'}>{buttonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Faculty List</h3>
                                </div>

                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(facultyList).length > 0 ? facultyList.map(f => (
                                                <tr>
                                                    {/* <td>{f.id}</td> */}
                                                    <td>{f.name}</td>
                                                    <td>
                                                        {(userType == 'H') ?
                                                            <>
                                                                <i>Disabled</i>
                                                            </> :
                                                            <>
                                                                <i onClick={() => updateMisc(f.id, f.name)} class="fas fa-user-edit" title='Edit Faculty'></i>
                                                            </>}

                                                    </td>
                                                </tr>
                                            )) :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faculty
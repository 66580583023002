import React, { useState, useEffect } from "react"
import Navbar from "./inc/Navbar"
import Sidebar from "./inc/Sidebar"
import Footer from "./inc/Footer"
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate()
    const [facultyCount, setFacultyCount] = useState("")
    const [departmentCount, setDepartmentCount] = useState("")
    const [teacherCount, setTeacherCount] = useState("")
    const [subjectCount, setSubjectCount] = useState("")
    const [enrolledSubjectCount, setEnrolledSubjectCount] = useState("")
    const [enrolledTeacherCount, setEnrolledTeacherCount] = useState("")
    const [submittedFeedback, setSubmittedFeedback] = useState("")
    const [subjectEvaluatedCount, setSubjectEvaluatedCount] = useState("")


    async function getCount(){
        let countRes = await fetch(window.api+"countData")
        countRes = await countRes.json()
        setFacultyCount(countRes.fac_count)
        setDepartmentCount(countRes.dept_count)
        setTeacherCount(countRes.teach_count)
        setSubjectCount(countRes.subj_count)
        setEnrolledSubjectCount(countRes.enr_subj)
        setEnrolledTeacherCount(countRes.enr_teach)
        setSubmittedFeedback(countRes.sub_feed)
        setSubjectEvaluatedCount(countRes.sub_feed_subj)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getCount()
    }, [])

    return (
        <>
            <div class="wrapper">
                {/* <div class="preloader flex-column justify-content-center align-items-center">
                    <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
                </div> */}
                <Navbar />
                <Sidebar />
                <div class="content-wrapper">
                    <div class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1 class="m-0">Dashboard</h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-info">
                                        <div class="inner">
                                            <h3>{facultyCount}</h3>
                                            <p>Faculties / Sub Campuses</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-success">
                                        <div class="inner">
                                            <h3>{departmentCount}</h3>
                                            <p>Total Departments</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-stats-bars"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-warning">
                                        <div class="inner">
                                            <h3>{teacherCount}</h3>
                                            <p>Total Teachers</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-person-add"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
                                    </div>
                                </div>
                                
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>{subjectCount}</h3>
                                            <p>Total Courses</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-pie-graph"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>{subjectEvaluatedCount}</h3>
                                            <p>Evaluated Courses</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-pie-graph"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>  */}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>{enrolledSubjectCount}</h3>
                                            <p>Class Formations (Courses)</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-pie-graph"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>  */}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-warning">
                                        <div class="inner">
                                            <h3>{enrolledTeacherCount}</h3>
                                            <p>Teachers Uploaded Formation</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-pie-graph"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>  */}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6">
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>{submittedFeedback}</h3>
                                            <p>Feedback Received</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-pie-graph"></i>
                                        </div>
                                        {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>

    )
}
export default Home